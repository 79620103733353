// use to write find contractors logics
//Skills keywords
export const PLUMBING_KEYWORDS = ["plum", "plumb", "plumber", "plumbing"];
export const PAINTING_KEYWORDS = ["painter", "painting", "paint", "pain"];


//Server congiguration
export const LOCAL_SERVER = "http://localhost:8080/rozgar/";
export const STAGING_SERVER = "https://testapi.bandhoo.com/rozgar/";
export const PROD_SERVER = "https://api.bandhoo.com/rozgar/";
export const STAGING_WEB_SERVER = "https://test.bandhoo.com/rozgar/"
export const PROD_WEB_SERVER = "https://app.bandhoo.com/rozgar/"
export const LOCAL_WEB_SERVER = "http://localhost:5000/rozgar/"

export const STAGING_ATTACHMENT_URL = "https://testapi.bandhoo.com/rozgar/attachment?path=";
export const PROD_ATTACHMENT_URL = "https://app.bandhoo.com/rozgar/attachment?path=";

//Google analytics tracking Ids
export const STAGING_GOOGLE_TRACKING_ID = "G-6KY9078S3D";
export const PROD_GOOGLE_TRACKING_ID = "G-F4TQLN3JPW";

// Uncomment for LOCAL
// export const ATTACHMENT_URL = STAGING_ATTACHMENT_URL;
// export const SERVER = LOCAL_SERVER;
// export const GOOGLE_TRACKING_ID = STAGING_GOOGLE_TRACKING_ID;
// export const BANDHOO_WEB_SERVER = LOCAL_WEB_SERVER;

// Uncomment for STAGING 
// export const ATTACHMENT_URL = STAGING_ATTACHMENT_URL;
// export const SERVER = STAGING_SERVER;
// export const GOOGLE_TRACKING_ID = STAGING_GOOGLE_TRACKING_ID;
// export const BANDHOO_WEB_SERVER = STAGING_WEB_SERVER;


// Uncomment for PROD 
export const ATTACHMENT_URL = PROD_ATTACHMENT_URL;
export const SERVER = PROD_SERVER;
export const GOOGLE_TRACKING_ID = PROD_GOOGLE_TRACKING_ID;
export const BANDHOO_WEB_SERVER = PROD_WEB_SERVER;


export const FIND_CONTRACTORS_API = SERVER +  "findcontractors";
export const CONTRACTOR_WEBSITE_API = SERVER + "website?siteName=";