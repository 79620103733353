<template>
  <div class="animationBtnContainer">
    <button class="animationBtn"
      :class="darkHover ? 'hoverDarkBtn' : 'hoverLightBtn'">
      <RouterLink v-if="openInSameTab" :to="location" class="btnLink"
        :class="darkHover ? 'hoverDarkLink' : 'hoverLightLink'">
        <span>{{ btnText }}</span> <i class="material-icons">arrow_right_alt</i>
      </RouterLink>
      <a v-else :href="location" class="btnLink"
        :class="darkHover ? 'hoverDarkLink' : 'hoverLightLink'" target="_blank">
        <span>{{ btnText }}</span> <i class="material-icons">arrow_right_alt</i>
      </a>
    </button>
  </div>
</template>

<script>
export default {
  name: "BandhooButton",
  props: ['btnText', 'darkHover', 'location', 'openInSameTab'],

}
</script>

<style scoped>
/* button animation starts */
.animationBtnContainer {
  width: 100%;
}
.animationBtn {
  display: block;
  position: relative;
  text-decoration: none;
  font-family: "Helvetica", sans-serif;
  background: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
}

.btnLink>span,
.material-icons {
  z-index: 5;
  position: relative;
  transition: color 350ms ease;
}

.btnLink::after,
.btnLink::before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.btnLink::before {
  transform: translateX(-100%);
  z-index: 1;
}

.btnLink:hover:before {
  transform: translateX(0);
  transition: transform 350ms ease;
}

.btnLink::after {
  z-index: 0;
  transform: translateX(100%);
  transition: none;
  transition: transform 350ms ease;
}

.btnLink:hover:after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}

.hoverLightLink::after,
.hoverLightLink::before {
  background: white;
}
.hoverLightLink:hover span,
.hoverLightLink:hover .material-icons {
  color: rgb(0, 0, 0);
}
.hoverDarkLink:hover span,
.hoverDarkLink:hover .material-icons {
  color: rgb(255, 255, 255);
}
.hoverDarkLink::after,
.hoverDarkLink::before {
  background: rgb(0, 0, 0);
}

.hoverLightLink>span {
  text-transform: uppercase;
}

.animationBtn > a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 32px;
  background-color: #b3d33c;
  color: white;
  font-size: 1rem;
}
/* button animation ends */
</style>