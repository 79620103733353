<template>
    <div class="ratingContainer">
        <div class="rating font_bold">
            <template v-for="star in Math.floor(ratingvalue)" :key="star">
                <i class="stars filled_stars">&#x2605; </i>
            </template>
            <template v-for="star in 5 - Math.floor(ratingvalue)" :key="star">
                <i class="stars filled_stars">&#x2606; </i>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "vueRating",
    props: ['ratingvalue'],

}
</script>

<style>
  .ratingContainer {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
  }
  .rating{
    color: #fd7a15 !important;
  }
  .font_bold {
    font-weight: 700;
  }
  .stars {
    font-size: 1.5rem;
    font-style: normal;
  }
</style>