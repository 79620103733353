<template>
  <div class="roleContainer">
    <PageTopImage 
      :image-path="imagePath"
      top-image-heading="For Contractors"
      top-image-description="How a Project Team benefits from Bandhoo"></PageTopImage>
    <section class="main">
      <div class="rolesImage"></div>
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          <div class="btnSection">
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="requestDemo.name" :dark-hover="true" :location="requestDemo.url"></BandhooButton>
            </div>
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url"></BandhooButton>
            </div>
          </div>
          <div class="contractorText">
            <h1>{{ contractorInfo.contractorMainHeading }}</h1>
            <p>{{ contractorInfo.benifitsHeading }}</p>
            <div class="benifitList">
              <ol>
                <li v-for="(item, index) in contractorInfo.benifitsList" :key="index">{{ item.listItem }}</li>
              </ol>
            </div>
            <h4>{{ contractorInfo.functionalitiesHeading }}</h4>
            <div class="functionalitiesList">
              <ol>
                <li v-for="(item, index) in contractorInfo.functionalitiesList" :key="index">{{ item.listItem }}</li>
              </ol>
            </div>
            <p>{{ contractorInfo.functionalitiesDescription }}</p>
            <h4>{{ contractorInfo.tenderingProcessHeading }}</h4>
            <div class="tenderingProcessList">
              <ol>
                <li v-for="(item, index) in contractorInfo.tenderingProcessList" :key="index">{{ item.listItem }}</li>
              </ol>
            </div>
          </div>
          <div class="contractorText">
            <ConstraComponent></ConstraComponent>
          </div>
          <div>
            <div class="iframeContainer">
              <iframe frameborder="0" allowfullscreen="1"
                defer="true"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                title="Managing Construction Projects Digitally" width="640" height="360"
                src="https://www.youtube.com/embed/i6gl5DMvzMA?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.bandhoo.com&amp;widgetid=1"
                id="widget2">
              </iframe>
            </div>
            <div class="btnSection">
              <div class="aboutRoleRequestBtn">
                <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url">
                </BandhooButton>
              </div>
              <div class="aboutRoleRequestBtn">
                <BandhooButton :btn-text="requestDemo.name" :dark-hover="true" :location="requestDemo.url">
                </BandhooButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BandhooButton from "@/components/Button.vue"
import RoleChange from "@/components/RoleChange.vue"
import PageTopImage from "@/components/PageTopImage.vue"
import ConstraComponent from "@/components/constra/constrcomponent.vue"
import { register, requestDemo } from '@/assets/db/static-data'
import { contractorInfo } from '@/assets/db/contractor-data'
export default {
  name: 'BandhooContractor',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
      requestDemo,
      contractorInfo,
      register
    }
  },
  components: {
    BandhooButton,
    RoleChange,
    PageTopImage,
    ConstraComponent
  }

}
</script>

<style scoped>
.btnSection {
  display: flex;
  justify-content: space-between;
}
.contractorText{
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #555555;
}
h1 {
  color: black;
}
ol {
  margin-left: 16px;
}
iframe,
.aboutRole,
.iframeContainer {
  width: 100%;
}

</style>
