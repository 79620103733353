<template>
    <div>
        <h4>Bandhoo-Constra</h4>
        <div style="margin-top: 10px;">
            Bandhoo has a solution called <a style='color:blue;font-weight:bold;' href='ai_project_monitoring'> CONSTRA </a>
            - which digitizes Construction progress monitoring and land surveying using AI/ML that processes data feed
            from cameras
            and drones. The tool creates virtual tours for each date of data-capture, and like a time-machine, you can
            travel back to a specific date and
            look at status of work completed at that date. This has tremendous value for Billing teams, CRM teams, and
            top managers who can't
            visit sites physically frequently.
            <h5 style="margin: 5px;">Three main benefits:</h5>
            <div style="margin-left: 21px;">
                <ol>
                    <li>Top team can review the project progress using virtual tours of the buildings, floors, units</li>
                    <li>Billing team can use the visuals to accurately assess work done and approve bills - think of it like
                        having
                        a time-machine when you can go back to a specific date and check what
                        was the status on that date
                    </li>
                    <li>CRM team can use the visuals to communicate to customers about status of construction (building/
                        floor/ unit)</li>
                    </ol>
                    <div class="youtubeContainer">
                        <iframe frameborder="0" allowfullscreen="1" defer="true" loading="lazy"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            title="Managing Construction Projects Digitally" width="640" height="360"
                            src="https://www.youtube.com/embed/CdNHUMHyOTA?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.bandhoo.com&amp;widgetid=1"
                            id="widget2">
                </iframe>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConstraComponent"
}
</script>

<style scoped>
.youtubeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
}
</style>