<template>
  <div class="roleContainer">
    <PageTopImage 
      :image-path="imagePath" 
      top-image-heading="Solutions"
      top-image-description="All solutions list">
    </PageTopImage>
    <section class="main">
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import RoleChange from "@/components/RoleChange.vue"
import PageTopImage from "@/components/PageTopImage.vue"
export default {
  name: 'BandhooSolutions',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
    }
  },
  components: {
    RoleChange,
    PageTopImage
  }

}
</script>

<style>
.content {
  padding: 36px 100px;
  display: flex;
  justify-content: space-between;
  gap: 72px;
}
@media screen and (max-width: 550px) {
  .content {
    padding: 36px 24px;
  }
}

@media screen and (max-width: 730px) and (min-width:550px) {
  .content {
    padding: 36px 24px;
  }

}
@media screen and (min-width: 730px) and (max-width:980px) {
  .content {
  padding: 36px 24px;
}
}
</style>
