export const filters = {
    date(seconds, format) {
       if (seconds > 0) {
           switch (format) {
               case "DD/MM": {
                   return dayjs(seconds * 1000).format("DD/MM");
               }
   
               case "DD-MM-YYYY HH:mm": {
                   return dayjs(seconds * 1000).format("DD-MM-YYYY HH:mm");
               }
   
               case "DD-MM-YYYY": {
                   return dayjs(seconds * 1000).format("DD-MM-YYYY");
               }
               case "DD-MMM-YY HH:mm": {
                   return dayjs(seconds * 1000).format("DD-MMM-YY HH:mm");
               }
               case "DD-MMM-YYYY": {
                   return dayjs(seconds * 1000).format("DD-MMM-YYYY");
               }
               case "DD-MMM-YYYY HH:mm": {
                   return dayjs(seconds * 1000).format("DD-MMM-YYYY HH:mm");
               }
               case "MMM-YYYY": {
                   return dayjs(seconds * 1000).format("MMM-YYYY");
               }
   
               case "YYYY-MM-DD": {
                   return dayjs(seconds * 1000).format("YYYY-MM-DD");
               }
               case "YYYY-MM-DD HH:mm": {
                   return dayjs(seconds * 1000).format("YYYY-MM-DD HH:mm");
               }
               case "HH:mm": {
                   return dayjs(seconds * 1000).format("HH:mm");
               }
               case "DD-MM-YY": {
                   return dayjs(seconds * 1000).format("DD-MM-YY");
               }
               case "hh:mm a": {
                   return dayjs(seconds * 1000).format("hh:mm a");
               }
               default: {
                   return "";
               }
           }
       } else {
           switch (format) {
               case "DD/MM": {
                   return dayjs().format("DD/MM");
               }
   
               case "DD-MM-YYYY HH:mm": {
                   return dayjs().format("DD-MM-YYYY HH:mm");
               }
   
               case "DD-MM-YYYY": {
                   return dayjs().format("DD-MM-YYYY");
               }
   
               case "YYYY-MM-DD": {
                   return dayjs().format("YYYY-MM-DD");
               }
               case "DD-MMM-YY HH:mm": {
                   return ""
               }
               case "DD-MMM-YYYY": {
                   return dayjs().format("DD-MMM-YYYY");
               }
               case "DD-MMM-YYYY HH:mm": {
                   return dayjs().format("DD-MMM-YYYY HH:mm");
               }
               case "MMM-YYYY": {
                   return dayjs().format("MMM-YYYY");
               }
               case "YYYY-MM-DD HH:mm": {
                 
                   return dayjs().format("YYYY-MM-DD HH:mm");
               }
               case "HH:mm": {
                   return dayjs().format("HH:mm");
               }
               case "DD-MM-YY": {
                   return dayjs().format("DD-MM-YY");
               }
               default: {
                   return "";
               }
           }
       }
    },
    urlencode: function (url) {
        return encodeURIComponent(url)
   },
   choice: function (value, dict, defaultt) {
       return dict.hasOwnProperty(value) ? dict[value] : defaultt;
   },
   
   /*If greater than required size adds 3 dot chars at end, so length will be requiredSize + 3 -> might break display*/
   truncate: function (message, requiredSize) {
       if (message && message.length > requiredSize) {
           return message.substr(0, requiredSize) + '...';
       } else {
           return message;
       }
   },
   
   currency: function (number, currency) {
       let formattedCurrency = new Intl.NumberFormat('en-IN', {style: 'currency', currency: currency != null ?currency : 'INR'}).format(number);
       let parts = formattedCurrency.split('.');
       let paise = Number(parts[1]); 
       if (paise > 0) {
           return formattedCurrency;
       } else {
           return parts[0];
       }
   },
   
   decimal: function (number, step) {
       let newStep = step || 2;
       let initialDecimal = number.toFixed(newStep);
       let afterPoint = initialDecimal.split(".");
       if(afterPoint[1] ==0){
           return afterPoint[0];
       } else{
           return initialDecimal;
       }
   },
   decimalToFixedStep: function (number, step) {
       step = step || 1;
       return number.toFixed(step);
   },
   
   int: function (number) {
       if(number){
           let roundedNumber = Math.round(number);
           return roundedNumber;
       } else{
           return 0;
       }
   },
   starScore: function(score){
       let finalScore =  (score < 0 ? 0 : 1 - score)*100 + '%';
       return finalScore;
   },
   quantity: function (number) {
       let formattedQuantity = new Intl.NumberFormat('en-IN',{ maximumFractionDigits: 3}).format(number);
       return formattedQuantity;
   },
   
   htmlwithline: function (s){
       var Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
       const diffstring =s.replace(/&amp;/g,"&" ).replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/\r\n/g, "<br>");
       // convert links to anchors
       const mainString = diffstring.replace(Rexp, "<a href=\"$1\">$1</a>");
       return mainString
   },
   
   ageCalculate: function (birthdayInSeconds) {
       const currentDateSeconds = Math.round(Date.now() / 1000)
       return Math.round((currentDateSeconds - birthdayInSeconds)/(365*24*60*60));
   },
   
   html: function (value){
       if (value == null) return "";
       return value.replace(/&amp;/g,"&" ).replace(/&lt;/g,"<").replace(/&gt;/g,">");
   },
   
   generateAvatar: function(userName){
       const firstLetterofName = userName.trim()[0].toUpperCase();
       
       //background colors for avatar
       const backgroundColors = {
           'blueOpal':'#0f3b57',
           'blueCharcoal':'#262b2f',
           'nightBlue':'#040348',
           'everGreen':'#11574a',
           'brickOrange':'#c14a09',
           'auraOrange':'#b4262a',
           'DeepMaroon':'#820000',
           'pinkRaspberry':'#980036',
           'holly':'#253426',
           'polishedBrown':'#985538'
       }
       
       const randombackGround = Math.floor(Math.random(0,9)*10);
       const foregroundColor = "white";
       const backgroundColor = backgroundColors[Object.keys(backgroundColors)[randombackGround]];
       
       const canvas = document.createElement('canvas');
       const context = canvas.getContext('2d');
       
       canvas.width = 200;
       canvas.height = 200;
       
       //Draw background
       context.fillStyle = backgroundColor;
       context.fillRect(0, 0, canvas.width, canvas.height);
       
       //Draw text
       context.font = "bold 100px Open sans";
       context.fillStyle = foregroundColor;
       context.textAlign = "center";
       context.textBaseline = "middle";
       context.fillText(firstLetterofName, canvas.width / 2, canvas.height / 2);
       
       return canvas.toDataURL("image/png");
       
   },
   lowerCase: function (word){
       if (!word) return "";
       return word.toLowerCase();
   },
   bandhooRepMobileORaccountRepMobile: function (bandhooRepMobile,accountRepMobile){
       if (bandhooRepMobile == null || bandhooRepMobile == '' || bandhooRepMobile == 'UNKNOWN') return accountRepMobile;
       return bandhooRepMobile;
   },
   indiaMobile: function (mobile){
       if (mobile == null || mobile == '') return mobile;
       return mobile.replace("+91", "");
   },
   abs: function (num){
       if(!num){
           return 0;
       }
       return Math.abs(num);
   },
   obfuscateName: function (name, label){ 
       let isFullScreen = JSON.parse(localStorage.getItem("isFullscreen"));  
       if(isFullScreen){
           if(Boolean(label)) {
               return label + ": ********"
           }
           else  return "********";
       }
       return name;
   },
   obfuscate: function (mobile, label){ 
       let isFullScreen = JSON.parse(localStorage.getItem("isFullscreen"));  
       if(isFullScreen){
           if(Boolean(label)) {
               return label + ": ****" + mobile.substring(4,6) + "**" + mobile.substring(8); 
           }
           else return "****" + mobile.substring(4,6) + "**" + mobile.substring(8);
       }
       return mobile;
   },
   sort:function (array, sortingField, order){ 
    let orderDecider = order != null && order.toLowerCase() == "asc" ? 1 : -1;
    let lessThanResult = -1 * orderDecider;
    let greaterThanResult = 1 * orderDecider;
    return array.sort((a, b) => {
       if (a[sortingField] < b[sortingField]) return lessThanResult;
       if(a[sortingField] > b[sortingField]) return greaterThanResult;
       return 0;
    });
 }
}