<template>
  <div>
    <div id="mask" class="mask">
      <div class="loadingWrapper" id="loadingWrapper">
      <img src="../public/img/loading.webp" alt="">
    </div>
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', 'Roboto', 'Poppins', sans-serif;
}

/* black #060607 */
/* dark-black #262529 */
/* defaut text color #555555 */
/* gray #1a191d */
/* light-gray #39383b */

/* default green #7c9f29 */
/* default yellow #fcd722 */

/* default btn color #b3d33c */

html {
  scroll-behavior: smooth;
}

body {
  background-color: #060607;
  color: white;
}

/* for Firefox */
::-moz-selection { 
  color: black;
  background: #9eea22;
}

::selection {
  color: black;
  background: #9eea22;
}

/* To remove increment/decrement icon from input typ number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
  border: none;
}

button:disabled {
  background-color: gray !important;
}

a {
  text-decoration: none;
}
.invalid {
  color: red !important;
}

.error-box {
  border: 2px solid #ff0000;
}
.warn-box {
  border: 2px solid #ffea00;
}
#mask {
  z-index: 1000;
  margin: auto;
  width: 100%;
  position: relative;
  display: none;
}

.mask img{
  width: 100px;
}
.loadingWrapper {
  position: fixed;
  top: 40%;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}
</style>
