<template>
  <div>
    <nav class="navbar">
      <div class="bandhooLogo">
        <RouterLink to="/">
          <img src="../assets/img/logom.png" alt="Bandhoo Logo" loading="lazy" />
        </RouterLink>
      </div>
      <div class="navLinksContainer">
        <div v-for="(link, index) in navLinks" class="navLink" :key="index">
          <span class="navHoverLine"></span>
          <RouterLink v-if="link.name != 'Solutions'" 
            :to="link.url" 
            @click="sendInformationToGoogleAnalitics(link.gtag)">
            {{ link.name }}
          </RouterLink>
          <div v-else 
            @mouseover="showDropdown(true)" 
            @mouseleave="showDropdown(false)"
            @click="sendInformationToGoogleAnalitics(link.gtag)" 
            class="solutionsLink">
            <span>{{ link.name }}</span>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
          <span></span>
          <div v-if="link.name == 'Solutions'" @mouseover="showDropdown(true)" @mouseleave="showDropdown(false)"
            class="soulutionsDropdownContainer navSoulutionsDropdownContainer" style="visibility: hidden;">
            <div v-for="(link, index) in rolesInfo" 
              :key="link + index" 
              class="soulutionsDropdown" 
              @click="hideNavItems(link.gtag)">
              <RouterLink :to="link.url">{{ link.role }}</RouterLink>
              <span></span>
            </div>
          </div>
        </div>
        <div class="navLink">
          <span class="navHoverLine"></span>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdQditzMzSBuq2J2urqc2OR28cjAFdTitdSHlOyXdHp6N2rfQ/viewform"
            target="_blank">Request
            Demo</a>
          <span></span>
        </div>
      </div>
      <div class="hamburgerNavbar">
        <div class="container navContainer">
          <input class="checkbox" type="checkbox" id="hamburgerCheckbox" />
          <div class="hamburgerLines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
          <div class="menuItems">
            <div v-for="(link, index) in navLinks" :key="link + index" class="menuLink">
              <RouterLink v-if="link.name != 'Solutions'" @click="hideNavItems(link.gtag)" :to="link.url">{{ link.name }}
              </RouterLink>
              <div v-else @click="showBurgerDropdown(showBurgerSolutions, true)" class="burgerDropdown">
                <span>{{ link.name }}</span><i class="material-icons" style="color: black;">keyboard_arrow_down</i>
              </div>
              <span></span>
              <div v-if="link.name == 'Solutions'"
                class="soulutionsDropdownContainer hamburgerSoulutionsDropdownContainer"
                style="visibility: hidden; height: 0;">
                <div v-for="(link, index) in rolesInfo" :key="link + index" class="hamburgerSoulutionsDropdown"
                  @click="hideNavItems(link.gtag)">
                  <RouterLink :to="link.url">{{ link.role }}</RouterLink>
                </div>
              </div>
            </div>
            <div class="menuLink">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdQditzMzSBuq2J2urqc2OR28cjAFdTitdSHlOyXdHp6N2rfQ/viewform"
                target="_blank">Request
                Demo</a>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { navLinks, aboutUs, appUtils, rolesInfo } from '@/assets/db/static-data.js'

export default {
  name: 'BandhooNavbar',
  props: [],
  data() {
    return {
      navLinks,
      rolesInfo,
      bandhooLogo: aboutUs.bandhooLogo,
      appUtils,
      showBurgerSolutions: true
    }
  },
  methods: {
    hideNavItems(gtagName) {
      let hamburgerCheckbox = document.getElementById('hamburgerCheckbox')
      hamburgerCheckbox.checked = false
      this.showDropdown(false)
      this.sendInformationToGoogleAnalitics(gtagName)
    },
    showDropdown(show, burger) {
      let soulutionsDropdownContainer;
      if (burger) {
        soulutionsDropdownContainer = document.querySelector('.hamburgerSoulutionsDropdownContainer')
      } else {
        soulutionsDropdownContainer = document.querySelector('.soulutionsDropdownContainer')
      }
      soulutionsDropdownContainer.style.height = show ? 'auto' : '0px'
      soulutionsDropdownContainer.style.visibility = show ? 'visible' : 'hidden'
      soulutionsDropdownContainer.style.opacity = show ? '1' : '0'
    },
    showBurgerDropdown(show, burger) {
      this.showBurgerSolutions = !this.showBurgerSolutions
      this.showDropdown(show, burger)
    },
    sendInformationToGoogleAnalitics(information) {
      console.log("Sending information to google analytics...  " + information);
      this.$gtag.event(information);
      console.log("Information sent to google analytics...");
    }
  },
  mounted() {
    this.showDropdown(false)
  }
}
</script>
navLinks
<style scoped>
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  padding: 0 24px;
  z-index: 100;
  background-color: #1a191d;
}

.navLinksContainer {
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 100%;
}

.soulutionsDropdown a {
  font-weight: 500;
  color: rgb(84, 84, 84);
  font-size: .9rem;
  padding: 0 24px;
}

.soulutionsDropdown {
  /* border-bottom: 1px solid rgb(245, 245, 245); */
  height: 45px;
  transition: all 300ms linear;
}

.navSoulutionsDropdownContainer {
  width: 240px;
  position: absolute;
  top: 85px;
  background-color: white;
  border-bottom: 4px solid #b3d33c;
  display: flex;
  flex-direction: column;
  transition: all 320ms ease-in-out;
}

.hamburgerSoulutionsDropdownContainer {
  top: 0;
  position: relative;
  padding: 0 16px;
}

.burgerDropdown {
  height: 45px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #0e2431;
}

.solutionsLink {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}

.soulutionsDropdown a:hover {
  color: #ffffff;
}
.soulutionsDropdown:hover {
  background: #b3d33c;
}

.navLink {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navLink a {
  height: 100%;
  display: flex;
  align-items: center;
}

.navHoverLine {
  width: 0%;
  height: 5px;
  background-color: white;
  border-radius: 4px;
  transition: 300ms linear;
}

.navLink:hover .navHoverLine {
  width: 100%;
}

.router-link-active {
  color: #b3d33c !important;
}

.soulutionsDropdown .router-link-active {
  color: white !important;
  background-color: #b3d33c !important;
}
.navLinksContainer>div>a {
  color: white;
}

/* hamburger */
.hamburgerNavbar {
  width: 100%;
  display: none;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.menuItems>div {
  display: flex;
  flex-direction: column;
}

.menuItems {
  padding: 0 24px;
}

.menuLink {
  border-bottom: 1px solid #d9d9d9
}

.menuLink a {
  width: 100%;
}

.hamburgerNavbar .navContainer li {
  list-style: none;
}

.navContainer a {
  color: #0e2431;
  font-weight: 600;
  font-size: .9rem;
  height: 42px;
  display: flex;
  align-items: center;
}

.hamburgerSoulutionsDropdown a {
  color: #525252;
  font-weight: 500;
}
.menuItems>div:nth-child(1) {
  margin-top: 36px;
}

.hamburgerNavbar .navContainer a:hover {
  font-weight: bolder;
}

.navContainer {
  display: block;
  height: 60px;
}

.navContainer .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 30px;
  right: 24px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.navContainer .hamburgerLines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 30px;
  right: 24px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navContainer .hamburgerLines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.navContainer .hamburgerLines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.navContainer .hamburgerLines .line2 {
  transition: transform 0.2s ease-in-out;
}

.navContainer .hamburgerLines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerNavbar .menuItems {
  background-color: white;
  height: calc(100vh - 85px);
  width: 260px;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  z-index: 10;
  top: 85px;
  left: 0;
  padding-left: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.hamburgerNavbar .menuItems li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.navContainer input[type="checkbox"]:checked~.menuItems {
  transform: translateX(0);
}

.navContainer input[type="checkbox"]:checked~.hamburgerLines .line1 {
  transform: rotate(45deg);
}

.navContainer input[type="checkbox"]:checked~.hamburgerLines .line2 {
  transform: scaleY(0);
}

.navContainer input[type="checkbox"]:checked~.hamburgerLines .line3 {
  transform: rotate(-45deg);
}

/* media queries starts */

/* mobile */
@media screen and (max-width: 550px) {

  /* navbar */
  .navbar {
    background-color: white;
  }

  .hamburgerNavbar {
    display: block;
  }

  .navLinksContainer {
    display: none;
  }

  .router-link-active {
    color: #b3d33c !important;
  }
}

@media screen and (max-width: 730px) and (min-width:550px) {
  .navbar {
    background-color: white;
  }

  .hamburgerNavbar {
    display: block;
  }

  .navLinksContainer {
    display: none;
  }

  .router-link-active {
    color: #b3d33c !important;
  }
}

/* tab */

@media screen and (min-width: 730px) and (max-width:980px) {
  .navbar {
    background-color: white;
  }

  .hamburgerNavbar {
    display: block;
  }

  .router-link-active {
    color: #b3d33c !important;
  }

  .navLinksContainer {
    display: none;
  }
}</style>