export const supplierInfo = {
  supplierMainHeading: "For Material Suppliers",
  supplierSubHeading: "Get access to material supply RFQs (Tenders) from clients",
  supplierDescription: "Most building materials are supplied through Distributors and Dealers. Bandhoo allows such distributors and dealers to register and get access to demand. Builders, EPC companies, construction contractors, and Facilities Management companies are all trying to adopt digital and achieve savings in their procurement. Registering with Bandhoo allows these distributors and dealers get easy access to demand.",
  supplierListHeading: "Any supplier of the following materials can sign up with Bandhoo:",
  supplierList: [
      { 
        listItem: "Aluminum Sections"
      },
      { 
        listItem: "Bitumen, Road Tar and related" 
      },
      { 
        listItem: "Boards" 
      },
      { 
        listItem: "Cement" 
      },
      { 
        listItem: "Chemicals and Adhesives" 
      },
      { 
        listItem: "Door Windows" 
      },
      { 
        listItem: "Ventilators" 
      },
      { 
        listItem: "Electrical Fittings" 
      },
      { 
        listItem: "Fabricated Steel Structures" 
      },
      { 
        listItem: "Fuel, Energy, DG sets" 
      },
      { 
        listItem: "Furniture and Wardrobes" 
      },
      { 
        listItem: "Glass Sheets and Glass related" 
      },
      { 
        listItem: "IT Telecom Equipment" 
      },
      { 
        listItem: "Landscape, Horticulture" 
      },
      { 
        listItem: "Lifts, Escalators and related" 
      },
      { 
        listItem: "Modular Kitchens and Appliances" 
      },
      { 
        listItem: "Ready Mix Concrete (RMC)" 
      },
      { 
        listItem: "Roofing Sheets and related" 
      },
      { 
        listItem: "Security equipment and services" 
      },
      { 
        listItem: "Stone and Aggregates" 
      },
      { 
        listItem: "Tiles and Flooring materials" 
      },
      { 
        listItem: "Water, Plumbing, Sewage & related" 
      }
  ]
}