<template>
  <div class="topImageContainer">
    <div class="topImage" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
    <div class="topContent">
      <h1>{{ topImageHeading }}</h1>
      <p>{{ topImageDescription }}</p>
      <div class="path">
        <div v-for="(path, index) in getPath" :key="index">
          <RouterLink :to="path.url">
            <span>{{path.name }} </span>
            <template v-if="index != getPath.length - 1">
              <i class="material-icons">arrow_right_alt</i>
            </template>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: "PageTopImage",
  props: ['imagePath', 'topImageHeading', 'topImageDescription'],
  computed: {
    backgroundImage() {
      return require(`@/assets/img/${this.imagePath}`);
    },
    getPath() {
      let fullPath = this.$route.path.split('/');
      let tempUrl = "";
      let path =[];
      fullPath.forEach((pageName) => {
        tempUrl = tempUrl + pageName;
        path.push({name:pageName || 'Home', url: tempUrl || "/"});
        tempUrl += "/";
      });
      return path;
    }
  },
  components: { RouterLink }
}
</script>

<style scoped>
.topImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 10;
  color: white;
  width: 75%;
  text-align: center;
}

.topContent>p {
  font-size: 1.15rem;
}

.topImage {
  margin-top: 85px;
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .75;
}

.path {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 12px;

}

h1 {
  font-size: 3rem;
}

.path a {
  color: rgb(255, 255, 255, 1);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: .85rem;
}

.path a span:hover {
  text-decoration: underline;
}

@media screen and (max-width: 550px) {

  .topContent>p,
  .path a {
    font-size: 1rem;
    font-weight: 500;
  }
  .path span {
    font-size: .8rem;
  }
  .topContent {
    width: 90%;
  }

  h1 {
    font-size: 2.5rem;
  }

}</style>