<template>
  <div class="footer">
    <div class="upperFooter">
      <div class="footerAboutBandhoo">
        <div class="bandhooLogo">
          <img src="../assets/img/logom.png" alt="Bandhoo Logo" loading="lazy" />
        </div>
        <div>
          Bandhoo’s mission is to help increase income opportunities for
          construction Workers and Contractors.
        </div>
        <div class="socialLinks">
          <div>
            <a href="https://www.youtube.com/@bandhooconstructionservices/videos">
              <img height="20" src="../assets/img/youtube1.png" alt="YouTube Logo">
            </a>
          </div>
          <div>
            <a href="https://in.linkedin.com/company/bandhoo-solutions-and-services-pvt-ltd">
              <img height="20" src="../assets/img/linkedin.png" alt="LinkedIn Logo">
            </a>
          </div>
        </div>
      </div>
      <div class="bandhooContacts">
        <h2>Contact</h2>
        <span v-for="(contact, index) in bandhooContacts" :key="contact.contactInfo + index" class="contactInfo">
          <a :href="contact.url" target="_blank">
            <template v-if="index != '1'"><i class="material-icons">{{ contact.icon }}</i></template>
            <template v-else><img style="height: 20px" src="../assets/img/whatsappwhite.png" alt="" /></template>
            <span>{{ contact.contactInfo }}</span>
          </a>
        </span>
      </div>
      <div class="quickLinks">
        <h2>Quick Links</h2>
        <ul>
          <li v-for="(link, index) in quickLinks" :key="index">
            <RouterLink :to="link.url" @click="sendInformationToGoogleAnalitics(link.gtag)">{{ link.linkName }}</RouterLink>
          </li>
        </ul>
      </div>
      <div class="downloadApp">
        <h2>Download App</h2>
        <p>
          Download Bandhoo app for Android or IOS or Web from the
          appropriate link below
        </p>
        <div class="downloadAndroidApp">
          <div v-for="(link, index) in appUtils" :key="index">
            <a :href="link.downloadLink" @click="sendInformationToGoogleAnalitics(link.gtag)">
              <img width="140" height="45" :src="require(`../assets/img/${link.image}`)" alt="Download App Logo" />
            </a>
          </div>
        </div>
        <div class="questions">
          <span>Have a question?</span><RouterLink to="/faqs">Click here!</RouterLink>
        </div>
      </div>
    </div>
    <div class="lowerFooter">
      <div class="copyright">
        © {{ copyrightYear }} Bandhoo. All rights reserved.
        <span class="copyrightBandhooLink"><a href="/">Bandhoo</a></span>
      </div>
      <div class="terms">
        <span><RouterLink to="/terms-of-use">Terms of Service</RouterLink></span>
        <span><RouterLink to="/privacy-policy">Privacy Policy</RouterLink></span>
      </div>
    </div>
    <BandhooFloats></BandhooFloats>
  </div>
</template>

<script>
import BandhooFloats from "@/components/Floats.vue"
import { aboutUs, appUtils, bandhooClients, bandhooContacts, quickLinks } from '@/assets/db/static-data'
export default {
  name: 'BandhooFooter',
  data() {
    return {
      appUtils,
      aboutUs,
      bandhooClients,
      quickLinks,
      bandhooContacts
    }
  },
  computed: {
    copyrightYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    sendInformationToGoogleAnalitics(information) {
      console.log("Sending information to google analytics...  " + information);
      this.$gtag.event(information);
      console.log("Information sent to google analytics...");
    }
  },
  components: {
    BandhooFloats
  }
}
</script>

<style scoped>
/* footer section starts */
.footer {
  font-size: .9rem;
}

.upperFooter {
  background-color: #1a191d;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 24px;
  padding: 72px 24px;
}

.socialLinks {
  display: flex;
}

.socialLinks>div>a {
  border: .4px solid rgb(133, 133, 133);
  padding: 12px;
  display: flex;
  align-items: center;
}

.footerAboutBandhoo {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bandhooContacts>h2,
.quickLinks>h2,
.downloadApp>h2 {
  margin-bottom: 16px;
}
.downloadAndroidApp {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contactInfo>a {
  display: flex;
  align-items: center;
}

.contactInfo>a,
.quickLinks>ul>li>a {
  gap: 8px;
  color: white;
}

.bandhooContacts,
.quickLinks,
.downloadApp,
.quickLinks>ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contactInfo>a>span:hover {
  color: #7c9f29;
}

.quickLinks>ul>li>a:hover {
  text-decoration: underline;
}

.quickLinks>ul {
  list-style: none;
}

.quickLinks>ul li::before {
  content: "\25A0";
  color: #7c9f29;
  margin-right: 8px;
}

.questions>a {
  color: white;
  margin: 0 8px;
  text-decoration: underline;
}

.lowerFooter {
  background-color: #060607;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.terms {
  display: flex;
  gap: 24px;
}

.terms>span>a {
  color: white;
}

.terms>span>a:hover {
  text-decoration: underline;
}

.copyrightBandhooLink>a {
  color: #7c9f29;
}

.questions>a:hover {
  color: #7c9f29;
}


/* media queries starts */

/* mobile */
@media screen and (max-width: 550px) {
  .upperFooter {
    grid-template-columns: repeat(1, 1fr);
    justify-items: left;
  }

  .lowerFooter {
    flex-direction: column;
    justify-content: space-around;
  }

  .bandhooContacts>h2,
  .quickLinks>h2,
  .downloadApp>h2 {
    margin-top: 24px;
  }
}

@media screen and (max-width: 730px) and (min-width:550px) {

  .upperFooter {
    grid-template-columns: repeat(1, 1fr);
    justify-items: left;
  }

  .lowerFooter {
    flex-direction: column;
    justify-content: space-around;
  }
}</style>