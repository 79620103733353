<template>
  <div class="about_contractor">
    <div class="contractor_image_container">
      <div class="company_image_wrapper" id="company_image_wrapper">
        <img :id="'company_image' + index"
          :src="skillImage(company.capabilityIds[0].name, index + 1)"
          loading="lazy"
          alt=""/>
      </div>
    </div>
    <div class="info">
      <div class="company_verified" :id="'company_verified' + index">
        <template v-if="company.premium">
          <svg style="fill: rgb(255, 171, 64)"  height="30px" viewBox="0 96 960 960">
            <path d="m338 1023-82-138-161-34 19-156L12 576l102-118-19-157 161-33 82-139 142 65 143-65 82 139 160 33-19 157 102 118-102 119 19 156-160 34-82 138-143-65-142 65Zm99-308 232-231-51-47-181 180-93-100-54 52 147 146Z"/>
          </svg>
          <span style="color: rgb(255, 171, 64)">Bandhoo Premium</span>
        </template>
        <template v-else-if="company.bandhooVerified">
          <svg fill="green" height="30px" viewBox="0 -960 960 960" width="24">
            <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
            />
          </svg>
          Bandhoo Verified
        </template>
      </div>
      <div class="company_rating font_bold" :id="'company_rating' + index">
        <template v-for="star in Math.floor(company.score)" :key="star">
          <i class="stars filled_stars">&#x2605; </i>
        </template>
        <template v-for="star in 5 - Math.floor(company.score)" :key="star">
          <i class="stars filled_stars">&#x2606; </i>
        </template>
      </div>
      <div class="company_gst" :id="'company_gst' + index">
        <template v-if="company.gstin">
          <svg height="20px" fill="green" viewBox="0 -960 960 960" width="24">
            <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q65 0 123 19t107 53l-58 59q-38-24-81-37.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-18-2-36t-6-35l65-65q11 32 17 66t6 70q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-56-216L254-466l56-56 114 114 400-401 56 56-456 457Z"
            />
          </svg>
          GST Registered
        </template>
      </div>
      <div class="company_name" :id="'company_name' + index">
        {{ company.name }}
      </div>
      <div class="company_location" :id="'company_location' + index">
        <svg fill="red" height="20px" viewBox="0 96 960 960">
          <path d="M479.885 570q30.477 0 52.796-21.704Q555 526.593 555 496.115q0-30.477-22.283-52.796Q510.433 421 479.956 421q-30.478 0-52.217 22.283Q406 465.567 406 496.044q0 30.478 21.704 52.217Q449.407 570 479.885 570ZM480 876q125.425-114.374 185.213-207.228Q725 575.918 725 503.916q0-111.778-70.894-182.347Q583.213 251 480.188 251t-173.607 70.812Q236 392.623 236 503.68q0 72.32 60.821 164.237T480 876Zm0 120Q312.025 852.903 228.512 730.273 145 607.643 145 503.869q0-157.257 100.951-250.563Q346.902 160 479.761 160q132.858 0 234.548 93.397Q816 346.794 816 504.206q0 103.555-84.013 226.148Q647.975 852.946 480 996Zm0-492Z"
          />
        </svg>
        {{ company.location }}
      </div>
      <div class="company_type">
        {{ company.ownership ? company.ownership : "" }}
      </div>
      <div class="capability_ids font_bold">
        <div class="capability_id" v-for="(capabilityId, index) in company.capabilityIds" :key="capabilityId + index">
          {{ capabilityId.msg }}
        </div>
      </div>
      <div class="enquiry">
        <div class="chat">
          <button class="text_default_black" @click="sendMessageToWhatsApp(company)">
            <img class="whatsapp_icon" :src="require('@/assets/img/whatsappicon.png')"  alt="" />Chat </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContractorCard",
  props: ["company", "index", "contractorSkills"],
  methods: {
    skillImage(capabilityId, index) {
      let images = this.contractorSkills[capabilityId].images;
      return require(`@/../public/img/skillsImages/${images[index]}.jpg`);
    },
    sendMessageToWhatsApp(company) {
      this.$gtag.event("landingpage_chat_button", {});
      let msg =  "I am interested in hiring " +  company.capabilityIds[0].msg + " subcontractors.";
      let url =  "https://api.whatsapp.com/send/?phone=918296747530&text=" +  msg + "&type=phone_number";
      window.open(url);
    },
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
}

.font_bold {
  font-weight: 700;
}

.text_default_black {
  color: rgb(80, 80, 80);
}

.text_color_orange {
  color: rgb(255, 153, 0);
}

.text_align_center {
  text-align: center;
}

.text_align_left {
  text-align: left;
}

.text_align_right {
  text-align: right;
}
.contractor_cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 80px;
  margin: 24px 0;
}
.main {
  padding: 24px;
  margin-top: 90px;
}

.contractor_image_container {
  width: 45%;
  display: flex;
  align-items: center;
}

.company_image_wrapper > img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 8px;
}

.about_contractor {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.company_name {
  text-transform: capitalize;
  font-size: 1rem;
  color: black;
}

.company_location,
.company_gst,
.company_verified {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}

.company_gst {
  align-items: center;
}

.company_verified {
  color: rgb(33, 122, 0);
  align-items: center;
  font-weight: 600;
  fill: rgb(33, 122, 0);
}

.gst_icon {
  color: green;
}

.location_icon {
  color: red;
}

.company_rating {
  color: #fd7a15 !important;
}

.capability_ids {
  display: flex;
  justify-content: flex-star;
  flex-wrap: wrap;
  gap: 12px;
}

.stars {
  font-size: 1.5rem;
  font-style: normal;
}

.capability_id {
  font-size: 0.75rem;
  border: 1px solid rgb(193, 193, 193);
  background-color: rgb(239, 239, 239);
  padding: 0 8px;
  color: rgb(111, 111, 111);
  border-radius: 4px;
}

.enquiry {
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
}

.whatsapp_icon {
  height: 16px;
  width: 16px;
}

.enquiry > div > button {
  border-radius: 4px;
  padding: 6px 16px;
  border: none;
  font-weight: 600;
  font-size: 0.85rem;
}

.chat > button {
  background-color: rgb(229, 229, 229);
  display: flex;
  align-items: center;
  gap: 6px;
}

/* bandhoo stats */
.bandhoo_stats_container {
  margin-top: 8px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.stats_content_outer {
  text-align: center;
}

.stats_content_inner {
  border: 2px solid rgb(204, 204, 204);
  border-radius: 4px;
  color: #4d4d4d;
  padding: 16px 0;
  background-color: rgb(248, 248, 248);
}

.stats_content_inner > h3 {
  letter-spacing: 2px;
  font-size: 2.25rem;
}

.stats_content_inner > h4 {
  font-size: 0.95rem;
  margin-top: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.info{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 4px;
}
/* media queries */

@media screen and (max-width: 550px) {
  .selectSkill {
    padding: 4px;
    height: 30px;
    font-size: 0.85rem;
    width: fit-content;
  }

  .searchJob input {
    padding: 4px;
    width: 110px;
    letter-spacing: normal;
    height: 30px;
  }

  .searchBtn {
    height: 30px;
    width: 100%;
  }

  .bandhoo_stats_container {
    grid-template-columns: 1fr;
  }

  .contractor_cards_container {
    grid-template-columns: 1fr;
  }

  .main {
    padding: 16px;
  }

  .enquiry > div > button {
    border-radius: 4px;
    padding: 4px 6px;
    border: none;
    font-size: 0.75rem;
  }
  .breadCrumbsContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 730px) and (min-width: 550px) {
  .bandhoo_stats_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .contractor_cards_container {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 730px) and (max-width: 930px) {
  .bandhoo_stats_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .contractor_cards_container {
    grid-template-columns: 1fr;
  }
}
</style>
