<template>
    <div class="stats">
        <div class="bandhooStatsContainer">
            <div v-for="(stats, index) in bandhooStats" :key="index" class="statsContentOuter">
                <span class="statsBorderTop"></span>
                <div class="statsContentInner">
                    <h3 :id="stats.role">{{ countDownTimers(stats.number, stats.role) }}+</h3>
                    <h4>{{ stats.role }}</h4>
                </div>
                <span class="statsBorderBottom"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { bandhooStats } from "@/assets/db/static-data";
export default {
    name: "BandhooStats",
    data() {
        return {
            bandhooStats,
        };
    },
    methods:{
        countDownTimers (targetNum, element) {
        let countDown = 0;
        const x = setInterval(function() {    
        if (countDown < targetNum) {
            countDown += Math.ceil(targetNum/100);
            if(document.getElementById(element)){
              document.getElementById(element).innerText = countDown + "+";
            }
        }else {
            if(document.getElementById(element)){
              document.getElementById(element).innerText = targetNum + "+";
            }
           clearInterval(x);
         }
        }, 1000/targetNum);
      }, 
    }
};
</script>

<style scoped>
/* statistics section starts */
.stats {
    background-color: #1a191d;
    position: relative;
}

.bandhooStatsContainer {
    width: 100%;
    padding: 36px 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.statsContentOuter {
    text-align: center;
    position: relative;
}
.statsBorderTop{
    position: absolute;
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 3px solid #b3d33c;
    border-left: 3px solid #b3d33c;
}
.statsBorderBottom{
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    border-right: 3px solid #b3d33c;
    border-bottom: 3px solid #b3d33c;
}
.statsContentInner {
    color: #ffffff;
    padding: 16px 0;
    background-color: #39383b;
}

.statsContentInner>h3 {
    letter-spacing: 2px;
    font-size: 2.25rem;
}

.statsContentInner>h4 {
    font-size: 0.95rem;
    margin-top: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media screen and (max-width: 550px) {
.bandhooStatsContainer {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 730px) and (min-width:550px) {
  .bandhooStatsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 730px) and (max-width:980px) {
.bandhooStatsContainer {
  grid-template-columns: repeat(2, 1fr);
}
}
/* statistics section ends */
</style>
