<template>
    <section>
      <div class="container">
        <div class="howToSection">
          <i class="material-icons">help</i>
          <h1>{{ heading }}</h1>
        </div>
        <div v-for="(faq, index) in faqs" :key="index" class="videosLink">
          <div @click="showOrHideDetails(index)" :class="{ 'active': faq.showDetails }" class="titleContainer">
            <h4>{{ faq.name }}</h4>
            <div>
              <i :style="{ fontSize: '1.5rem', color: faq.showDetails ? 'white' : '#90ce14' }" class="material-icons">
                {{ faq.showDetails ? 'remove' : 'add_box' }}
              </i>
            </div>
          </div>
          <div v-for="(detail, idx) in faq.details" :key="idx" v-show="faq.showDetails" class="details">
            <p v-if="detail.title" class="bold">{{ detail.title }}</p>
            <p v-if="detail.description" v-html="detail.description" class="description"></p>
            <a v-if="detail.link" :href="detail.link">{{ detail.link }}</a>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'FAQComponent',
    props: ['heading', 'faqs'],
    data() {
      return {
      }
    },
    methods: {
      showOrHideDetails(index) {
        return this.faqs[index].showDetails = !this.faqs[index].showDetails
      }
    }
  
  }
  </script>
  
  <style scoped>
  section {
    background-color: #fff;
    color: rgb(88, 88, 88);
  }
  
  .container {
    padding: 36px 100px;
  }
  
  .howToSection {
    font-size: 1.5rem;
    margin: 24px 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .howToSection .material-icons {
    font-size: 3.5rem; 
    color: #90ce14
  }
  
  .videosLink {
    border: 1px solid rgba(128, 128, 128, 0.31);
  }
  
  .titleContainer {
    padding: 16px;
    cursor: pointer;
    transition: all 500ms ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .details {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .details .bold {
    font-weight: 600;
  }
  
  .details>a {
    color: gray;
  }
  
  .details>a:hover {
    color: #90ce14;
  }
  
  .description{
    padding: 10px !important;
  }
  .active {
    background-color: black;
    color: white;
  }
  
  @media screen and (max-width: 550px) {
    .container {
      padding: 36px 24px;
    }
    .howToSection {
      font-size: 1rem;
    }
    .howToSection .material-icons {
      font-size: 2rem;
    }
  }
  
  @media screen and (max-width: 730px) and (min-width:550px) {
    .container {
      padding: 36px 24px;
    }
  
    .howToSection {
      font-size: 1rem;
    }
    .howToSection .material-icons {
      font-size: 2rem;
    }
  }</style>
  