<template>
  <section>
    <div class="floats">
      <div id="jobsModal" class="modal">
        <div class="modalContent">
          <div class="modalHeading">
            <span class="close" @click="closeModal">&times;</span>
            <div v-if="formUsedFor != 'tenders'"
              style="display: flex;flex-direction: row;align-content: flex-start;justify-content: flex-end;align-items: center;font-size: 14px; justify-content: flex-end; gap: 8px;">
              {{ $t('Change Language') }}:
              <span style="display:inline-block; margin: 16px 0;">
                <button class=""
                  style="position:relative;min-width: 80px; border-radius: 2px;border: none;font-weight: bold; padding: 5px;"
                  v-if="$i18n.locale == 'hi'" type="button" value="null" @click="changeLanguage('en')">{{ "English"
                  }}</button>
                <button class="" v-else type='button'
                  style="position:relative;min-width: 80px; border-radius: 2px;border: none;font-weight: bold; padding: 5px;" value="null"
                  @click="changeLanguage('hi')">{{ "हिंदी" }}</button>
              </span>
            </div>
          </div>
          <div v-if="showForm">
          <FindWorksForm :scope="formUsedFor" :form-used-for="formUsedFor" skill="" pincode=""></FindWorksForm>  
          </div>   
        </div>
      </div>
      <div id="findTenders" class="requestDemoFloats" @click="findTenders('tenders')">
         <button>
           {{ $filters.msg('FIND_WORK_ON_THEKA') }} 
         </button>
      </div>
      <div id="myBtn" class="requestDemoFloats" @click="openModal('jobs')">
        <button>
          {{ $filters.msg("FIND_WORKS_ON_DEHADI")}}
        </button>
      </div>
      <div id="bestQuotesBtn" class="requestDemoFloats" @click="openModal('contractors')">
          <button>
            Get Best Quotes
          </button>
      </div>
      <div class="registerFloats requestDemoFloats" @click="sendInformationToGoogleAnalitics('website_register_button')">
        <button>
          <a :href="register.url" target="_blank">
            <img src="../assets/img/whatsappwhite.png" alt="WhatsApp Register" />Register
          </a>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { register } from '@/assets/db/static-data';
import FindWorksForm from "@/components/FindWorksForm.vue"

export default {
  name: 'BandhooFloats',
  data() {
    return {
      register,
      showForm: false,
      formUsedFor: ''
    }
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
    },
    findTenders(){
      if(this.$route.path == "/findtenders"){
         this.formUsedFor = "tenders";
         this.showForm = true
         $("jobsModal").style.display = "block";
      }else{
        window.open('/findtenders', '_blank');
      }
    },
    openModal(formUsedFor) {
      if(formUsedFor == 'contractors') {
        this.$i18n.locale = 'en'; //default english for contractors
        if(window.location.pathname == '/findcontractors'){
          $("jobsModal").style.display = "block";
          this.showForm = true
          this.formUsedFor = formUsedFor;
        } else {
          window.open('/findcontractors', '_blank');
        }
        this.sendInformationToGoogleAnalitics('landingpage_bestquote_float')
      } else {
        $("jobsModal").style.display = "block";
        this.showForm = true
        this.formUsedFor = formUsedFor;
        this.sendInformationToGoogleAnalitics('website_' + formUsedFor + "_Float")
      }
    },
    closeModal() {
      this.showForm = false
      $("jobsModal").style.display = "none";
    },
    sendInformationToGoogleAnalitics(information) {
      console.log("Sending information to google analytics...  " + information);
      this.$gtag.event(information);
      console.log("Information sent to google analytics...");
    }
  },
  mounted: function () {
    this.$i18n.locale = "hi"
    let speech;
    this.$nextTick(()=>{
      speech = new speechRecognizer($("websitepincode"), 'start_img', "websitepincode.");
    });
  },
  components: {
    FindWorksForm
}
}
</script>
<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  margin-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalHeading {
  width: 100%;
  position: relative;
}

.modalContent {
  background-color: #1e1e1e;
  color: rgb(255, 255, 255);
  margin: auto;
  padding: 16px 40px;
  border-radius: 8px;
  width: 60%;
  max-width: 360px;
  /* display: flex;
  justify-content: space-between; */
}

::placeholder {
  color: rgba(255, 255, 255, 0.468);
}

.modalContent>h2 {
  margin: 16px 0;
}

.modalFindWorks>button {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #25d366;
  color: white;
}

.close {
  color: #ffffff;
  float: right;
  font-size: 36px;
  font-weight: 500;
  z-index: 10;
  position: absolute;
  right: -30px;
  top: -24px;
}

.close:hover,
.close:focus {
  color: #6dc911;
  text-decoration: none;
  cursor: pointer;
}

.floats {
  position: fixed;
  bottom: 50px;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  z-index: 101;
}

.registerFloats {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.registerFloats>button {
  border-radius: 3px;
}

.registerFloats a,
.requestDemoFloats button {
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 3px;
  font-weight: 600;
}

.requestDemoFloats button {
  padding: 8px 12px;
}

.registerFloats button,
.requestDemoFloats button {
  background-color: #25d366;
}

.requestDemoFloats>a {
  color: rgb(255, 255, 255);
}

.registerFloats>button>a>img {
  height: 20px;
}

/* media queries starts */

/* mobile */
@media screen and (max-width: 550px) {

  .userInfo,
  .modalHeading {
    width: 100%;
  }

  .formImg {
    display: none;
  }

  .modalContent {
    width: 90%;
    flex-direction: column;
    gap: 40px;
  }

}

@media screen and (max-width: 730px) and (min-width:550px) {

  .userInfo,
  .modalHeading {
    width: 100%;
  }

  .formImg {
    display: none;
  }

  .modalContent {
    width: 80%;
    flex-direction: column;
    gap: 40px;
  }
}

/* tab */

@media screen and (min-width: 730px) and (max-width:980px) {
  .modalContent {
    width: 70%;
  }
}
</style>