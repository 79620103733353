<template>
  <div class="roleContainer">
    <PageTopImage :image-path="imagePath" top-image-heading="For Developers"
      top-image-description="How Bandhoo’s PLM helps you digitally run your Procurement and Projects"></PageTopImage>
    <section class="main">
      <div class="rolesImage"></div>
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          <div class="aboutRoleRequestBtn">
            <BandhooButton :btn-text="requestDemo.name" :dark-hover="true" :location="requestDemo.url"></BandhooButton>
          </div>
          <div class="developerHeader">
            <h1>{{ developerInfo.developerMainHeading }}</h1>
            <p>{{ developerInfo.plmHelps }}</p>
          </div>
          <h4>{{ developerInfo.developerSubHeading }}</h4>
          <p>{{ developerInfo.developerDescription }}</p>
          <h4>{{ developerInfo.bandhooSupportHeading }}</h4>
          <ol>
            <li v-for="(detail, index) in supportDetails" :key="index" v-html="detail.listItem"></li>
          </ol>
          <p>{{ developerInfo.supportDescription }}</p>
          <div class="developerHeader">
           <ConstraComponent></ConstraComponent>
          </div>
        </div>
      </div>
      <div class="moreDevloperDetails">
        <div class="ytContainer">
          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="Managing Construction Projects Digitally" width="640" height="360"
              src="https://www.youtube.com/embed/i6gl5DMvzMA?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.bandhoo.com&amp;widgetid=1"
              id="widget2">

            </iframe>
          </div>

          <div class="reverseAuction">
            <span></span>
            <p>How Reverse Auction works for Material Procurement?</p>
            <span></span>
          </div>

          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
              src="https://www.youtube.com/embed/f0B1xHlcfU8?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.bandhoo.com&amp;widgetid=3"
              id="widget4"></iframe>
          </div>
        </div>

        <div class="benefits">
          <div style="background-color: #a8e717; padding: 8px; text-align: center;">
            <h1>A Complete Digital Transformation</h1>
          </div>
          <div
            style="font-weight: bold; margin-top: 24px; padding: 16px; display: flex; justify-content: space-between; border: 1px solid rgb(198, 198, 198);">
            <span>Activity/Ares</span>
            <span>From</span>
            <span>To</span>
          </div>
          <div class="benifitList">
            <div v-for="(list, index) in digitalTransformation" :key="index">
              <ol>
                <li v-for="(item, index) in list.lists" :key="index">{{ item.listItem }}</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="featuresContainer">
          <div style="background-color: #a8e717; padding: 8px; text-align: center;">
            <h1>Features</h1>
          </div>
          <div class="featureList" style="display: flex; justify-content: space-between; margin-top: 24px">
            <div v-for="(list, index) in features" :key="index" style="display: flex; flex-direction: column; gap: 16px;">
              <span v-for="(item, index) in list.lists" :key="index">{{ item.listItem }}</span>
            </div>
          </div>
        </div>

        <div class="refImages">
          <div v-for="(refImage, index) in developerInfo.refImages" :key="index" class="refImageContainer">
            <img :src="require(`@/assets/img/${refImage.src}`)" alt="Developer Reference Image">
          </div>
          <div class="bottomRequestDemo">
            <BandhooButton :btn-text="requestDemo.name" :dark-hover="true" :location="requestDemo.url"></BandhooButton>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BandhooButton from "@/components/Button.vue"
import RoleChange from "@/components/RoleChange.vue"
import PageTopImage from "@/components/PageTopImage.vue"
import ConstraComponent from "@/components/constra/constrcomponent.vue"
import { rolesInfo, requestDemo } from '@/assets/db/static-data'
import { developerInfo, digitalTransformation, supportDetails, features } from '@/assets/db/developer-data'
export default {
  name: 'BandhooDeveloper',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
      rolesInfo,
      requestDemo,
      developerInfo,
      supportDetails,
      digitalTransformation,
      features
    }
  },
  components: {
    BandhooButton,
    RoleChange,
    PageTopImage,
    ConstraComponent
  }

}
</script>

<style>
.requestDemoBtn,
.requestDemoBtn button {
  width: 100%;
}

.roleContainer {
  background-color: white;
  color: black;
}

.bottomRequestDemo {
  margin-bottom: 50px;
}

.bottomRequestDemo>div,
.aboutRoleRequestBtn div {
  display: flex;
  justify-content: flex-end;
}
</style>

<style scoped>
.benefits {
  margin-top: 24px;
  font-size: .9rem;
  color: #555555;
}

.benifitList {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.ytContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.moreDevloperDetails {
  padding: 36px 100px;
}

.developerHeader {
  margin: 36px 0;
}

.reverseAuction {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  font-weight: 600;
  color: #3e3e3e;

}

.reverseAuction>span {
  border: 1px solid rgb(208, 208, 208);
}

.roleChange {
  display: flex;
  flex-direction: column;
  border-top: .75px solid rgb(215, 215, 215);
  border-bottom: .5px solid rgb(215, 215, 215);
}

.ourSolutionHeading {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.roleLink:hover {
  background-color: #b3d33c;
}

.roleChange>div:hover .role {
  color: white;
}

.contacts {
  background-color: black;
  color: white;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contacts>h1,
.contactInfo,
.contactHeading,
.contactLinks {
  padding: 0 36px;
}

.contactInfo>a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.contacts>h1 {
  margin-top: 24px;
}

.roleLink {
  height: 60px;
  border-top: .75px solid rgb(224, 224, 224);
  border-left: .75px solid rgb(224, 224, 224);
  border-right: .75px solid rgb(224, 224, 224);
  transition: all 300ms linear;
}

.ourSolutionHeading {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #262529;
  font-size: 1.5rem;
  font-weight: bold;
}

.ourSolutionHeading>span {
  margin-left: 16px;
  color: white;
}

.aboutRole, .featuresContainer {
  margin: 24px 0;
  color: #555555;
  width: 100%;
  font-size: .9rem;
}
.aboutRole h4 {
  margin: 16px 0;
}

ol {
  margin: 0 0 16px 16px;
}

.roleLink>a {
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: rgb(101, 101, 101);
  width: 100%;
  height: 100%;
}

.contactLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
}

.refImages {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.refImageContainer,
.refImageContainer>img {
  width: 100%;
}

/* media queries starts */

/* mobile */
@media screen and (max-width: 550px) {
  .roleContainer {
    font-size: .85rem;
  }

  .content,
  .featureList,
  .benifitList {
    flex-direction: column;
  }

  .roleContainer {
    padding: 0;
  }

  .ytContainer {
    align-items: flex-start;
  }

  iframe,
  .aboutRole,
  .iframeContainer {
    width: 100%;
  }

  .moreDevloperDetails {
    padding: 36px 24px;
  }
}

@media screen and (max-width: 730px) and (min-width:550px) {
  .content {
    flex-direction: column;
  }

  .ytContainer {
    align-items: flex-start;
  }

  .roleContainer {
    padding: 0;
  }

  iframe,
  .aboutRole,
  .iframeContainer {
    width: 100%;
  }

}
</style>
