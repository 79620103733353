<template>
    <div>
      <div class="main">
        <div :style="{marginTop: notFrame ? '' : '-100px'}">
          <h2 v-if="findType == 'tenders'" class="description">बंधु पर सबसे अच्छे ठेके के काम ढूंढे </h2>
          <h2 v-else class="description">Get Best Quotes from Contractors on Bandhoo
          </h2>
        </div>
        <div class="searchComponentContainer">
          <form @submit.prevent style="display: flex; gap: 10px;flex-wrap: wrap;align-items: baseline;justify-content: center;">
            <search-component :skills="skills" v-model:searchedSkill="searchedSkill" 
            v-model:pin="pin" :searchFunction="findJobs" :notFrame="notFrame"></search-component>
            <div v-if="findType == 'tenders'" class="sendEnquiry">
              <button  type="button" @click="openModal('tenders')" :disabled="!(pin && pin.length == 6)">
                {{ $filters.msg('FIND_WORK') }} 
              </button>
            </div>
            <div v-else-if="notFrame" class="sendEnquiry">
              <button id="getQuotesBtn" type="button" @click="openModal('contractors')" :disabled="!(pin && pin.length == 6)">Get Best Quotes</button>
            </div>
          </form>
        </div>
        <div id="jobsModal2" class="modal">
          <div class="modalContent">
            <div class="modalHeading">
              <span class="close" @click="closeModal">&times;</span>
              <div v-if="findType != 'tenders'"
                style="display: flex;flex-direction: row;align-content: flex-start;justify-content: flex-end;align-items: center;font-size: 14px; justify-content: flex-end; gap: 8px;">
                {{ $t('Change Language') }}:
                <span style="display:inline-block; margin: 16px 0;">
                  <button class=""
                    style="position:relative;min-width: 80px; border-radius: 2px;border: none;font-weight: bold; padding: 5px;"
                    v-if="$i18n.locale == 'hi'" type="button" value="null" @click="changeLanguage('en')">{{ "English"
                    }}</button>
                  <button class="" v-else type='button'
                    style="position:relative;min-width: 80px; border-radius: 2px;border: none;font-weight: bold; padding: 5px;" value="null"
                    @click="changeLanguage('hi')">{{ "हिंदी" }}</button>
                </span>
              </div>
            </div>
            <div v-if="showForm">
              <FindWorksForm :scope="formUsedFor" :form-used-for="formUsedFor" :skill="searchedSkill" :pincode="pin"></FindWorksForm>
            </div>
          </div>
        </div>
        <div class="breadCrumbsContainer">
          <h3 v-if="findType == 'tenders'" class="breadCrums">
            सभी टेंडर > <span class="breadCrumSkill">{{  $t(searchedSkill) }}  टेंडर </span> >  {{ $filters.quantity(findtenders.length) }} +
            <template v-if="Boolean(searchedCityName)"> 
               {{ searchedCityName }} {{ searchedStateName }} में
            </template>   
          </h3>
          <h3 v-else class="breadCrums">
            All contractors > <span class="breadCrumSkill"> {{ searchedSkillName }}</span>
            Contractors > {{ searchedCityName }} {{ searchedStateName }}
            {{ $filters.quantity(numContractors) }} + found
          </h3>
        </div>
        <div class="contractor_cards_container card" v-if="!dataLoaded">
          <div v-for="(company, index) in 20" class="contractor_card card__body" :key="index">
            <unloaded-cards :searched-skill="searchedSkill" :contractor-skills="skills" :index="index"></unloaded-cards>
          </div>
        </div>
        <!-- Tenders find section start -->
        <div v-else-if="findType == 'tenders'"  class="contractor_cards_container">
          <div v-for="(tender, index) in findtenders" class="contractor_card" :key="index">
            <div v-if="index < 20" class="about_contractor"> 
              <div class="contractor_image_container">
                <div class="company_image_wrapper" id="company_image_wrapper">
                  <img :id="'company_image' + index" :src="skillImage(tender.capabilityIds[0].name, index + 1)"
                    loading="lazy" alt="">
                </div>
              </div>
              <div style="width: 55%;">
                <!-- TENDERS FIND CARD START-->
                 <TendersFindCard
                   :mytender="tender"
                   :user="user"
                   :scope="'findtenders['+ index +'].'"
                   :now-seconds="nowSeconds"
                   :show-table="false">
                 </TendersFindCard> 
                 <!-- TENDERS FIND CARD ENDS -->
                 <div class="buttonContainer">
                   <button class="button" @click="openModal('tenders')">
                    <b v-if="tender.tenderConfig.flow.name == 'Light'">{{ $filters.msg("I am interested")}}</b>             
                    <b v-else>{{ $filters.msg("View Details")}}</b>       
                   </button>
                 </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tenders find section end-->
        <div v-else class="contractor_cards_container">
          <div v-for="(company, index) in companies" class="contractor_card" :key="index">
            <contractor-card :company="company" :contractor-skills="skills" :index="index"></contractor-card>
          </div>
        </div>
      </div>
    </div>
    <div>
      <SkillList :skills="contractorSkills" :title="findType == 'tenders' ? 'Tenders by skill' : 'Contractors by Skills'" :pincode="pin"></SkillList>
    </div>
    <div>
      <PopularCityList :popular-cities="popularCities" :title="findType == 'tenders' ? 'Tenders by city' : 'Contractors by Cities'" :skill="searchedSkill"></PopularCityList>
    </div>
  </template>
  <script>

 

  import FindWorksForm from "@/components/FindWorksForm.vue";
  import { appUtils, bandhooStats, navLinks, rolesInfo, 
    aboutUs, bandhooClients, bandhooContacts, quickLinks, contractorSkills, materialSkills, popularCities} from '@/assets/db/static-data';
  import { FIND_CONTRACTORS_API, PLUMBING_KEYWORDS, PAINTING_KEYWORDS, SERVER } from "@/assets/db/config"
  import SearchComponent from './SearchComponent.vue';
  import ContractorCard from './ContractorCard.vue';
  import UnloadedCards from './UnloadedCards.vue';
  import TendersFindCard from './TendersFindCard.vue';
  import SkillList from "./SkillList.vue";
  import PopularCityList from "./popularCityList.vue";
  export default {
    name: "FindContractorsComponent",
    props:['notFrame', 'findType'],
    data() {
      let isMaterialTender = !this.notFrame && location.search.includes("isMaterialTender=true");
      return {
        appUtils,
        navLinks,
        bandhooStats,
        rolesInfo,
        aboutUs,
        bandhooClients,
        bandhooContacts,
        quickLinks,
        companies: [],
        dataLoaded: false,
        searchedSkill: 'PLUMBER',
        searchedSkillName: 'Plumbing',
        numContractors: 14036,
        searchedStateName: '',
        searchedCityName: '',
        pin: null,
        showForm: false,
        isMaterialTender:isMaterialTender,
        materialSkills:materialSkills,
        contractorSkills: contractorSkills,
        skills: isMaterialTender ?  materialSkills : contractorSkills,
        findtenders:[],
        nowSeconds:0,
        user:{
          permissions:[],
          company:{}
        },
        popularCities:popularCities
      };
    },
    components: {
      FindWorksForm,
      TendersFindCard,
      SearchComponent,
      ContractorCard,
      UnloadedCards,
      SkillList,
      PopularCityList
    },
    watch: {
     '$route'() {
      //If route is changed, update the results
       this.findContractors(this.composeUrlFromLocationUrl());
    }
   },
    methods: {
      skillImage(capabilityId, index) {
       let images = this.skills[capabilityId].images;
       return require(`../../public/img/skillsImages/${images[index]}.jpg`);
      },
      shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
      },
      fillImages() {
        for (let contractorSkillKey in this.skills) {
          let contractorSkill = this.skills[contractorSkillKey];
          for (let imgSkill of contractorSkill.imgSkills) {
            for (let i = 0; i < this.skills[imgSkill].numDirectImages; i++) {
              contractorSkill.images.push(imgSkill + (i + 1));
            }
          }
          while (contractorSkill.images.length <= 20) {
            contractorSkill.images.push(...contractorSkill.images);
          }
        }
      },
      changeLanguage(language) {
        this.$i18n.locale = language;
      },
      openModal(formUsedFor) {
        if (formUsedFor == 'contractors') {
          this.$i18n.locale = 'en'; //default english for contractors
          if (window.location.pathname == '/findcontractors') {
            $("jobsModal2").style.display = "block";
            this.showForm = true
            this.formUsedFor = formUsedFor;
          } else {
            window.open('/findcontractors', '_blank');
          }
          this.sendInformationToGoogleAnalitics('landingpage_bestquote_float')
        } else {
          $("jobsModal2").style.display = "block";
          this.showForm = true
          this.formUsedFor = formUsedFor;
          this.sendInformationToGoogleAnalitics('website_' + formUsedFor)
        }
      },
      closeModal() {
        this.showForm = false
        $("jobsModal2").style.display = "none";
      },
      getSkillParam(keywords) {
        let capabilityIdParams = "PLUMBER";
        let plumbingMatchingReg = new RegExp(PLUMBING_KEYWORDS.join("|"), "gi");
        let paintingMatchingReg = new RegExp(PAINTING_KEYWORDS.join("|"), "gi");
        if (keywords.match(plumbingMatchingReg)) {
          capabilityIdParams = "PLUMBER";
        }
        if (keywords.match(paintingMatchingReg)) {
          capabilityIdParams = "PAINTER";
        }
        this.searchedSkill = capabilityIdParams;
        return capabilityIdParams;
      },
      makeURL(pincode, skill) {
        this.searchedSkill = skill;
        let url = FIND_CONTRACTORS_API + "?capabilityId=" + skill + "&isMaterialTender=" + this.isMaterialTender;
        if (pincode != null && pincode != "") {
          url += "&pincode=" + pincode;
        }
        if(this.findType == "tenders"){
          return url + "&action=findTenders&hl=hi";
        }
        return url + "&hl=en";
      },
      sendInformationToGoogleAnalitics(information) {
        console.log("Sending information to google analytics... " + information);
        this.$gtag.event(information, {});
        console.log("Information sent to google analytics...");
      },
      findJobs() {
        let url = this.makeURL(this.pin, this.searchedSkill)
        this.searchedSkillName = this.skills[this.searchedSkill].name;
        this.findContractors(url);
      },
      findContractors(url) {
        let contractorSkill = this.skills[this.searchedSkill];
       this.shuffleArray(contractorSkill.images);
        signalWaitMode();
        this.dataLoaded = false;
        if(this.notFrame){
          this.sendInformationToGoogleAnalitics("findsubcontractors_search");
        }else{
          //to show waitIcon on Iframe
          $('loadingWrapper').style.top = "2%";
          this.sendInformationToGoogleAnalitics("website_findsubcontractors");
        }
        fetch(url, {
          credentials: "include"
        }).then((resp) => {
          return resp.json();
        }).then((result) => {
          if(this.findType == "tenders"){
            this.findtenders = result.findtenders || [];
            this.searchedStateName = result.state ? result.state : '';
            this.nowSeconds = result.nowSeconds;
          }else{
            this.companies = result.companies;
            this.numContractors = result.numContractors;
            this.searchedStateName = result.state ? result.state + ' >' : '';
          }
          this.searchedCityName = result.city ? result.city + ', ' : '';
          this.dataLoaded = true;
          signalWaitOver();
        })
      },
      composeUrlFromLocationUrl(){
        let search = location.search;
        let locationUrl = new URLSearchParams(search);
        this.searchedSkill = locationUrl.get("capabilityId") || (this.isMaterialTender ? 'Steel' : this.getSkillParam(search));
        this.pin = locationUrl.get("pincode") || null;
        let url = this.makeURL(this.pin,  this.searchedSkill);
        return url;
      }
    },
    created() {
      this.fillImages();
      this.findContractors(this.composeUrlFromLocationUrl());
      this.sendInformationToGoogleAnalitics("landingpage_" + this.findType + "_find_contractors");
    }
  };
  </script>
    
  <style scoped>
  .searchComponentContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 8px;
    justify-content: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .buttonContainer{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .button{
    background: rgb(161,70,33);
    font-weight: 700;
    color: rgb(255,255,255);
    padding: 10px;
    border-radius: 2px;
    text-transform: uppercase;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    font-size: 14px;
  }
  .breadCrumSkill{
    background: rgb(34 100 9);
    padding: 0 4px 0 4px;
    margin: 0 4px 0 5px;
  }
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    margin-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  .modalContent {
    background-color: #1e1e1e;
    color: rgb(255, 255, 255);
    margin: auto;
    padding: 16px 40px;
    border-radius: 8px;
    width: 60%;
    max-width: 360px;
    /* display: flex;
    justify-content: space-between; */
  }
  
  .modalHeading {
    width: 100%;
    position: relative;
  }
  
  .close {
    color: #ffffff;
    float: right;
    font-size: 36px;
    font-weight: 500;
    z-index: 10;
    position: absolute;
    right: -30px;
    top: -24px;
  }
  
  .close:hover,
  .close:focus {
    color: #6dc911;
    text-decoration: none;
    cursor: pointer;
  }
  .enterPincode {
    padding: 4px 16px;
    border: none;
  }
  
  button {
    cursor: pointer;
  }
  
  .font_bold {
    font-weight: 700;
  }
  
  .text_default_black {
    color: rgb(80, 80, 80);
  }
  
  .text_color_orange {
    color: rgb(255, 153, 0)
  }
  
  .text_align_center {
    text-align: center;
  }
  
  .text_align_left {
    text-align: left;
  }
  
  .text_align_right {
    text-align: right;
  }
  
  .breadCrumbsContainer {
    margin: 24px 0;
  }
  
  .breadCrums {
    margin: 20px 0px;
    border: 1px solid #ffffff33;
    padding: 4px;
    border-radius: 5px;
  }
  
  .description {
    text-align: center;
    border: 1px solid #ffffff33;
    border-radius: 5px;
    padding: 20px;
  }
  
  .contractor_card {
    border: 2px solid rgb(222, 222, 222);
    padding: 12px;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: black;
  }
  
  .navbar {
    padding: 8px 0;
  }
  
  .navbar>div {
    padding: 0 24px;
  }
  
  .contractor_cards_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 80px;
    margin: 24px 0;
  }
  
  .find_contractors_heading_container {
    margin: 40px 0 30px 0;
  }
  
  .find_contractors_heading {
    color: rgb(37, 37, 37);
  }
  
  .main {
    padding: 24px;
    margin-top: 90px;
  }
  
  .contractor_image_container {
    width: 45%;
    display: flex;
    align-items: center;
  }
  
  .company_image_wrapper>img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }
  
  .about_contractor {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  .company_name {
    text-transform: capitalize;
    font-size: 1rem;
    color: black;
  }
  
  .company_location,
  .company_gst,
  .company_verified {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .company_gst {
    align-items: center;
  }
  
  .company_verified {
    color: rgb(33, 122, 0);
    align-items: center;
    font-weight: 600;
    fill: rgb(33, 122, 0);
  }
  
  .gst_icon {
    color: green;
  }
  
  .location_icon {
    color: red;
  }
  
  .company_rating {
    color: #fd7a15 !important;
  }
  
  .capability_ids {
    display: flex;
    justify-content: flex-star;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .stars {
    font-size: 1.5rem;
    font-style: normal;
  }
  
  .capability_id {
    font-size: .75rem;
    border: 1px solid rgb(193, 193, 193);
    background-color: rgb(239, 239, 239);
    padding: 0 8px;
    color: rgb(111, 111, 111);
    border-radius: 4px;
  }
  
  .enquiry {
    margin-top: 8px;
    display: flex;
    justify-content: flex-start;
  }
  
  .searchButtonContainer {
    display: flex;
    gap: 16px;
  }
  
  .sendEnquiry>button {
    min-width: 160px;
    background-color: #25d366;
    color: white;
    border-radius: 1px;
    padding: 8px 16px;
    border: none;
    font-weight: 600;
    font-size: .85rem;
    height: 40px;
  }
  
  .whatsapp_icon {
    height: 16px;
    width: 16px;
  }
  
  .enquiry>div>button {
    border-radius: 4px;
    padding: 6px 16px;
    border: none;
    font-weight: 600;
    font-size: .85rem;
  }
  
  .chat>button {
    background-color: rgb(229, 229, 229);
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  /* bandhoo stats */
  .bandhoo_stats_container {
    margin-top: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
  
  .stats_content_outer {
    text-align: center;
  }
  
  .stats_content_inner {
    border: 2px solid rgb(204, 204, 204);
    border-radius: 4px;
    color: #4d4d4d;
    padding: 16px 0;
    background-color: rgb(248, 248, 248);
  }
  
  .stats_content_inner>h3 {
    letter-spacing: 2px;
    font-size: 2.25rem;
  }
  
  .stats_content_inner>h4 {
    font-size: .95rem;
    margin-top: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  
  /* media queries */
  
  @media screen and (max-width: 730px) {
    .selectSkill {
      padding: 4px;
      height: 30px;
      font-size: .85rem;
      width: fit-content;
    }
  
    .searchJob input {
      padding: 4px;
      width: 110px;
      letter-spacing: normal;
      height: 30px;
    }
  
    .searchBtn {
      height: 30px;
      width: 100%;
    }
  
    .bandhoo_stats_container {
      grid-template-columns: 1fr;
    }
  
    .contractor_cards_container {
      grid-template-columns: 1fr;
    }
  
    .main {
      padding: 16px;
    }
  
    .info {
      font-size: .85rem;
      gap: 2px;
    }
  
    .enquiry>div>button {
      border-radius: 4px;
      padding: 4px 6px;
      border: none;
      font-size: .75rem;
    }
    .sendEnquiry{
      margin: auto;
      margin-top: -20px;
    }
    .sendEnquiry button {
      width: 250px;
      margin: -20px 0 10px 0px;
      height: 35px;
    }
  
    .breadCrumbsContainer {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 770px) and (min-width:550px) {
    .bandhoo_stats_container {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .contractor_cards_container {
      grid-template-columns: 1fr;
    }
  }
  
  @media screen and (min-width: 730px) and (max-width:930px) {
    .bandhoo_stats_container {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .contractor_cards_container {
      grid-template-columns: 1fr;
    }
  }
  
  @media screen and (min-width: 970px) {}
  
  
  /* Skelton css */
  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .skeleton_star {
    animation: skeleton-loading_star 1s linear infinite alternate;
    color: gray !important;
  }
  
  @keyframes skeleton-loading_star {
    0% {
      opacity: 0%;
    }
  
    100% {
      opacity: 80%;
      ;
    }
  }
  
  .skeleton_img {
    animation: skeleton-loading_star 1s linear infinite alternate;
    background: rgba(128, 128, 128, 0.732);
    border: 1px solid gray;
    border-radius: 5px;
    width: 80%;
    max-width: 280px;
  }
  
  @keyframes skeleton-loading_star {
    0% {
      opacity: 0%;
    }
  
    100% {
      opacity: 20%;
      ;
    }
  }
  
  .skeleton-verify {
    animation: skeleton-loading_star 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading_star {
    0% {
      opacity: 10%;
    }
  
    100% {
      opacity: 80%;
      ;
    }
  }
  
  .skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
  }
  
  @media screen and (max-width: 600px) {
    .searchJob {
      flex-direction: column;
      gap: 5px;
    }
  
    .selectSkill {
      width: 250px;
    }
  
    .searchJob>div {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
    }
  
    .searchInput {
      width: 250px;
    }
  
    .searchInput>input {
      width: 250px;
    }
  
    .searchInput>img {
      height: 30px;
    }
  
    .searchButtonContainer {
      width: 250px;
      flex-direction: column;
    }
  
    .modalContent {
      width: 90%;
      flex-direction: column;
      gap: 40px;
    }
  }</style>
    