export const developerInfo = {
  developerMainHeading: "For Developers",
  plmHelps: "How Bandhoo’s PLM helps you digitally run your Procurement and Projects",
  developerSubHeading: "Builder or Developer",
  developerDescription: "Builders have stringent requirements of qualification for picking their contractors or material suppliers. Bandhoo supports builders in configuring their list of pre- qualified contractors and material suppliers.This allows the Builder’s procurement team to run an efficient RFQ process and get lowest possible price quotes for whatever they are trying to buy.",
  bandhooSupportHeading: "Bandhoo supports multiple types of tendering process:",
  supportDescription: "Builder can manage the Daily Project Reporting through Bandhoo’s platform. The contractor or subcontractor’s team can use the Bandhoo App to update the daily progress achieved. The builder’s own site team can validate/ approve the reported progress. The same information can be used on monthly basis for raising bills. An integrated Project reporting system of this kind ensure complete transparency from top-to-bottom and across the entire organization as well as its subcontractors.",
  refImages: [
    {
      src: "developersa.jpg"
    },
    {
      src: "developersb.jpg"
    },
    {
      src: "developersc.jpg"
    }
  ]
}

export const supportDetails = [
  { 
    listItem: "Buyer can do a real-time tender, like a reverse auction, where bidders are only allowed to reduce their bids in real-time"
  },
  { 
    listItem: "Buyer can do a multi-round tender such that every time a bidding round ends, the buyer can decide whether he/she wants to start another follow-on round to further reduce price quotes" 
  },
  { 
    listItem: "Buyer can do a confidential bidding round, which means nobody gets to the see the bid values until the bid deadline has elapsed Bandhoo also offers “Procurement As a Service (Paas)” to builders so that they can always have the best possible contractors and suppliers available to them." 
  }
]

export const features = [
  {
    lists: [
      {
        listItem: "Specially built for Indian Construction use-cases"
      },
      {
        listItem: "Cloud hosted with browser-based access"
      }
    ]
  },
  {
    lists: [
      {
        listItem: "Works for all projects – Real Estate, Infrastructure, Industrials"
      },
      {
        listItem: "Automated workflows with stringent controls"
      }
    ]
  },
  {
    lists: [
      {
        listItem: "Covers all stages of a Project"
      },
      {
        listItem: "Enables collaboration with external Consultants/ Agencies"
      }
    ]
  }
]

export const digitalTransformation = [
  {
    lists: [
      {
        listItem: "Budgeting and Estimation"
      },
      {
        listItem: "Contracting"
      },
      {
        listItem: "Procurement"
      },
      {
        listItem: "Project Reporting"
      },
      {
        listItem: "RA bill processing"
      },
      {
        listItem: "Variation Order management"
      }
    ]
  },
  {
    lists: [
      {
        listItem: "Excel and email based"
      },
      {
        listItem: "Excel and email based; Quotes submitted in paper format"
      },
      {
        listItem: "Emails and Phone calls based"
      },
      {
        listItem: "Excel and email based"
      },
      {
        listItem: "Paper based (then punched into ERP)"
      },
      {
        listItem: "Excel and email based"
      }
    ]
  },
  {
    lists: [
      {
        listItem: "Budgeting and Estimation"
      },
      {
        listItem: "Contracting"
      },
      {
        listItem: "Procurement"
      },
      {
        listItem: "Project Reporting"
      },
      {
        listItem: "RA bill processing"
      },
      {
        listItem: "Variation Order management"
      }
    ]
  }
]
