export const faq = [
  {
    name: 'Contractors',
    details: [
      {
        title: "How a Project Team benefits from Bandhoo",
        link: "https://youtu.be/vo-ni42w7Us",
      },
    ],
    showDetails: false,
  },
  {
    name: 'Thekedars',
    details: [
      {
        title: "Customer Testimonial",
        link: "https://youtu.be/D1jFoWFzLxM",
      },
      {
        title: "Kya faayde ho sakte hain Bandhoo istemaal karke",
        link: "https://youtu.be/jfxCyoLHz8E",
      },
      {
        title: "Score kaise badhayen",
        link: "https://youtu.be/8IY3vX60ksc",
      },
      {
        title: "Maasik Shulk Kyon Bharen",
        link: "https://youtu.be/uWPYHnCrTpk",
      },
    ],
    showDetails: false,
  },
  {
    name: 'Workers',
    details: [
      {
        title: "DigitalShramik (this is a full playlist)",
        link: "https://youtube.com/playlist?list=PL-1TqIUh4v6kUFLrxehcMy8nZHZWuXGiG",
      },
      {
        title: "Kaise phone ke zariye kaam paa sakte hain",
        link: "https://youtu.be/zKPJkrDjAY0",
      },
    ],
    showDetails: false,
  },
  {
    name: 'Developers',
    details: [
      {
        title:
          "How Bandhoo’s PLM helps you digitally run your Procurement and Projects",
        link: "https://youtu.be/i6gl5DMvzMA",
      },
      {
        title: "How Reverse Auction works for Material Procurement?",
        link: "https://youtu.be/f0B1xHlcfU8",
      },
    ],
    showDetails: false,
  },
];


export const constraFaqs = [
  {
    name: 'What is CONSTRA?',
    details:[
      {
        description:`CONSTRA is India's leading AI-based visual intelligence platform available as a Cloud-Saas solution. 
          CONSTRA helps you capture the status of your site using cutting-edge software that uses AI/ML to 
          help you document your project status across its entire lifecycle. We generate virtual tours and 
          videos of your project site that you can access easily from your laptop on a browser. When 
          compared to other typical project monitoring solutions (e.g., Falconbrick, Powerplay, Onsite, Procore), 
          our key differentiator is that our solution truly requires almost no manual effort or data entry.`
      }
    ],
      showDetails: false
  },
  {
    name:"What are the most common uses for CONSTRA Capture?",
    details:[
        {
        description:`There are four main benefits that CONSTRA provides -
          <ol type='i'>
            <li> Senior leaders can review the project status from their offices without having to
              physically visit the site everytime
            </li>
            <li>Snags can be marked by multiple users within the virtual tours so that nothing gets
              missed, and you are not relying on only a couple of site team members for it
            </li>
            <li>Billing team can use the same visual evidence to approve the bills based on work done
            </li>
            <li> CRM team can use the same visuals to inform customers about status of their units and
              correspondingly solicit payments from them when certain milestones are completed
          </ol>
          </ol>`
      }
     ],
    showDetails: false
  },
  {
    name:"Who should/can use CONSTRA?",
    details:[
      {
        description:`CONSTRA is extremely useful for Real Estate companies, Contractors, and PMCs. Anyone walking the
          project site can create a capture using CONSTRA. Anyone with access to your CONSTRA project can
          view images and add Field Notes/Snags.`
      }
    ],
    showDetails: false
  },
  {
    name:"How do you create a site capture with CONSTRA?",
    details:[
      {
        description:`You simply attach a 360° camera to your hard hat and connect it to your smartphone. Do a
          walkthrough of the site and tap record in our APP. You can capture 360° photos and videos. Our AI-
          based software engine maps these images to floor plans and BIM, giving you existing site condition
          views in minutes.`
      }
    ],
    showDetails: false
  },
  {
    name:"If I use CONSTRA, do you provide someone for the capture?",
    details:[
      {
        description:`CONSTRA is designed to be so easy that your existing project team can capture the site by simply
          walking. All you need is an off-the-shelf 360° camera and our mobile application. Your field teams
          know the site best. The vast majority of our customers use existing staff for this purpose. We can
          train the staff suitably.
          If you still need someone additionally, we can provide a trained individual to do the data capture.
          For drone-based capture for exteriors/ land, we provide a specialist.`
      }
    ],
    showDetails: false
  },
  {
    name:"Can CONSTRA save us money?",
    details:[
      {
        description:`Absolutely. If used in the right way, there are several sources of value from CONSTRA. The first and
          foremost is accurate Billings. Additionally you save cost from reduced travel to the site, fewer on-site
          investigations, better workflows for QA/QC, more effective project and resource planning, and faster
          handover to customers.`
      }
    ],
    showDetails: false
  },
  {
    name:"What does CONSTRA cost?",
    details:[
      {
        description:`We offer flexible subscription pricing that is based on the amount of construction you do. Please
          write to use to get your customized price proposal.`
      }
    ],
    showDetails: false
  },
  {
    name:"If I’m ready to use CONSTRA, how can I sign up?",
    details:[
      {
        description:`Simple! You can email us at info@bandhoo.com. Someone from our team will be in touch with you
          to get you set up. Or you can call our helpline +91-6364432123`
      }
    ],
    showDetails: false
  },
  {
    name:"Can I take regular mobile phone photos and upload those to CONSTRA?",
    details:[
      {
        description:`Yes! You can take photos and notes while you walk, and our tech will automatically pin those images
          and comments to the location where the note was made. In addition, you can take mobile phone
          photos (and standard 360° photos) and manually pin them to your floor plan at any time.`
      }
    ],
    showDetails: false
  },
  {
    name:"How many people can I invite to view a project?",
    details:[
      {
        description:`We don’t limit access based on user count. You can add as many users to a project as you like.`
      }
    ],
    showDetails: false
  },
  {
    name:"What cameras do you support?",
    details:[
      {
        description:`Currently we support Insta360 set of cameras`
      }
    ],
    showDetails: false
  },
]