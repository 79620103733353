export const homeOwnerInfo = {
  homeOwnerMainHeading: "For Home Owners",
  homeOwnerSubHeading: "Get access to material supply RFQs (Tenders) from clients",
  homeOwnerDescription: "Download the App, register, set up your home address as your Project, and publish your requirements as a Tender. For any help, call our helpline. They will connect you to someone in our Sales team. Or you can write to ",
  homeOwnerList: [
      { 
        listItem: "You can find good quality contractors to build your dream home"
      },
      { 
        listItem: "You can find Painting thekedars who can do complete painting of your home" 
      },
      { 
        listItem: "You can find specialized contractors for waterproofing, plumbing, electrical, and flooring works for your home" 
      }
  ]
}