<!-- COPY FROM TENDERSFINDCARD.CVJSONT  -->
<template>
    <div style="max-width:100%;" :id="scope + 'projectDetails'">
        <div style="border-radius:2px;margin: 0 8px" :id="scope + 'projectName'">
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px 2px 10px 2px;">
                <div>
                    <span class="wp-mode">
                        <template v-if="mytender.isRealTime"><span class="blink">{{ $filters.msg("Auction") }}</span></template>
                        <template v-else-if="mytender.tenderConfig.flow.name == 'PQList'">{{ $filters.msg("PQ List (Restricted)")}}</template>
                        <template v-else>{{ $filters.msg("Public") }}</template>
                    </span>
                </div>
                <div class="mdl-typography--text-right">
                    <span class="wp-badge">{{ mytender.docNumber }}</span>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap;margin: 10px 2px 10px 2px;">
                <div>
                    <a href="javascript:void(0)" v-if="shouldObfuscate" style="color:blue;"
                        @click="showConfirmation(user.company.premium)">
                        {{ $filters.obfuscateName(mytender.project.company.name, "Company") }}
                    </a>
                    <span v-else>{{ $filters.obfuscateName(mytender.project.company.name, "Company") }}</span>
                </div>
                <div class="mdl-typography--text-right">{{ mytender.location }}</div>
            </div>
            <div v-if="user.permissions && user.permissions.includes('BasicOps')"
                style="display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px 2px 10px 2px;">
                <div class="mdl-typography--text-left">{{ $filters.msg("Project") }}:
                    <template v-if="user.company.id == mytender.project.company.id">
                        <a :href="'projectview#projects.path=' + mytender.project.path"><b>{{ $filters.obfuscateName(mytender.project.name) }}</b></a>
                    </template>
                    <template v-else>
                        <template v-if="user.permissions && user.permissions.includes('Ops')">
                            <a :href="'projectview#projects.path=' + mytender.project.path"><b>{{ $filters.obfuscateName(mytender.project.name) }}</b></a>
                        </template>
                        <template v-else>
                            <b>
                                <a href="javascript:void(0)" v-if="shouldObfuscate"
                                    @click="showConfirmation(user.company.premium)">
                                    {{ $filters.obfuscateName(mytender.project.name) }}
                                </a>
                                <span v-else>{{ $filters.obfuscateName(mytender.project.name) }}</span>
                            </b>
                        </template>
                    </template>
                </div>
                <div class="mdl-typography--text-right"><span class="wp-status">{{ mytender.status }}</span></div>
            </div>
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px 2px 10px 2px;">
                <div>
                    <b v-if="mytender.tenderConfig.flow.name == 'Light'">
                        {{ $filters.quantity(mytender.quantity) }} {{ mytender.metric }} &times; {{
                            $filters.quantity(mytender.pieceRate) }} =
                    </b>
                    <b>&#x20B9;{{ $filters.quantity(mytender.value) }}</b>
                </div>
                <div class="mdl-typography--text-right">{{ $filters.msg("EMD") }}: <b>&#x20B9;{{
                    $filters.quantity(mytender.EMD_value) }}</b></div>
            </div>
            <template v-if="showTable">
                <!-- <tender-boq :mytender="mytender" isplp="true"></tender-boq> -->
            </template>
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px 2px 10px 2px;">
                <div v-if="mytender.capabilityIds != null" style="max-width: 200px; word-break: break-word">
                    <template v-for="(capabilityId, index) in mytender.capabilityIds">
                        {{ capabilityId.msg}}
                        <template v-if="index != mytender.capabilityIds.length - 1">,</template>
                    </template>
                </div>
                <div class="mdl-typography--text-right">{{ $filters.msg("Type") }}:
                    <template
                        v-if="mytender.tenderConfig.tenderType == 'Labour' || mytender.tenderConfig.tenderType == 'LabourAndMaterial'">
                        {{ $filters.msg("Labour") }}<template
                            v-if="mytender.tenderConfig.tenderType == 'LabourAndMaterial'"> {{ $filters.msg("and") }}
                        </template>
                    </template>
                    <template
                        v-if="mytender.tenderConfig.tenderType == 'Material' || mytender.tenderConfig.tenderType == 'LabourAndMaterial'">
                        {{ $filters.msg("Materials") }}
                    </template>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px 2px 10px 2px;">
                <div>{{ $filters.msg("Deadline") }}:
                  <template v-if="remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds) > 7">
                    {{ $filters.date(mytender.bidDeadlineDateSeconds, "DD-MM-YYYY") }}
                    <template v-if="mytender.isRealTime"> {{ $filters.date(mytender.bidDeadlineDateSeconds, "hh:mm a")}}</template>
                  </template>
                  <template v-else-if="remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds) <= 7 && remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds) >= 4">
                    <b> {{ remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds)}} {{ $filters.msg("BID_DEADLINE_DAYS_REMAINING")}}</b>
                  </template>
                  <template v-else-if="remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds) <= 3 && remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds) >= 1">
                    <b style="color:red;"> {{ remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds)}} {{ $filters.msg("BID_DEADLINE_DAYS_REMAINING")}}</b>
                  </template>
                  <template v-else-if="remainingBidDeadLineDays(mytender.bidDeadlineDateSeconds) == 0">
                    <b  style="color:red;"> {{ $filters.msg("BID_DEADLINE_EXPIRING_TODAY")}}</b>
                  </template>
                  <template v-else>
                    <b  style="color:red;"> {{ $filters.msg("BID_DEADLINE_EXPIRED_ON")}} {{ $filters.date(mytender.bidDeadlineDateSeconds, "DD-MM-YYYY")}} </b>
                  </template>
                </div>
            </div>
            <div class="mdl-typography--text-left" style="width:200px;">
                <vue-rating :ratingvalue.sync="mytender.priority" :docpath="mytender.path" :scope="scope"
                    :score-permission="false"></vue-rating>
            </div>
        </div>
    </div>
</template>

<script>
import VueRating from './vueRating.vue';

export default {
    name: "TendersFindCard",
    components:{
      VueRating
    },
    props: ['mytender', 'user', 'scope', 'showTable', 'shouldObfuscate', 'nowSeconds'],
    methods: {
        showConfirmation() {

        },
        remainingBidDeadLineDays(tenderBidDeadLineSeconds){
         return Math.round((tenderBidDeadLineSeconds - this.nowSeconds)/(24*60*60));
        }
    }
}
</script>

<style>.wp-badge {
    border-radius: 16px;
    background-color: rgb(255, 171, 64);
    font-weight: bold;
    padding: 2px 6px;
}

.wp-mode {
    border-radius: 16px;
    background-color: rgb(188 183 177);
    font-weight: bold;
    padding: 2px 6px;
}</style>