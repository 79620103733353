export const contractorInfo = {
  contractorMainHeading: "For contractors",
  benifitsHeading: "As a Contractor, you can get many benefits by using Bandhoo’s solution and services. ",
  benifitsList: [
      { 
        listItem: "Fully digitize your entire Procurement and save costs on materials"
      },
      { 
        listItem: "Search for small contractors and thekedars who are willing to work at your rates" 
      },
      { 
        listItem: "Search for worker teams who can work on daily wage basis" 
      },
      { 
        listItem: "Digitally manage your Projects " 
      }
  ],
  functionalitiesHeading: "The functionalities that are available to this segment are",
  functionalitiesList: [
      { 
        listItem: "Issuing tenders for subcontracting to get lowest possible price quotes from reliable and good quality subcontractors. Tenders can be posted as Labour only or Labour plus Material contracts depending on user requirements"
      },
      { 
        listItem: "Submitting EOI (Expression of Interest) and Quotations" 
      },
      { 
        listItem: "Issuing tenders for building material purchases to get lowest possible price quotes" 
      },
      { 
        listItem: "Posting worker jobs and hiring them against those jobs" 
      },
      { 
        listItem: "Project reporting integrated with project billing" 
      }
  ],
  functionalitiesDescription: "Large contractors have stringent requirements of qualification for picking their subcontractors and material suppliers. Bandhoo supports large contractors in configuring their list of pre-qualified subcontractors and material suppliers.",
  tenderingProcessHeading: "Bandhoo supports multiple types of tendering process",
  tenderingProcessList: [
      { 
        listItem: "Buyer can do a real-time tender, like a reverse auction, where bidders are only allowed to reduce their bids in real-time"
      },
      { 
        listItem: "Buyer can do a multi-round tender such that every time a bidding round ends, the buyer can decide whether he/she wants to start another follow-on round to further reduce price quotes" 
      },
      { 
        listItem: "Buyer can do a confidential bidding round, which means nobody gets to the see the bid values until the bid deadline has elapsed" 
      }
  ],
}
