<template>
  <div>
    <section class="main">
      <div class="hero">
        <div class="heroImages">
          <div class="heroImageContainer"></div>
        </div>
        <div class="heroText">
          <div class="heroHeading">
            <h1 class="purposeHeading">
              We help Developers, PMCs and Contractors save costs and time in
              their Project.
            </h1>
          </div>
          <div class="heroDescription">
            <p class="purposeDescription">
              {{ aboutUs.purposeDescription }}
            </p>
          </div>
        </div>

      </div>
      <BandhooStats></BandhooStats>
      <div class="rolesInfoContainer">
        <div v-for="(role, index) in rolesInfo" :key="index" class="roleInfo">
          <h3 class="roleHeading"><a href="">{{ role.role }}</a></h3>
          <p>{{ role.description }}</p>
          <div class="detailsArrowContainer">
            <button class="detailsArrow">
              <a :href="role.url" class="rolesRedirect btnLink">
                <i class="material-icons">chevron_right</i>
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="bandhooYouTubeLink">
      <div class="youTubeLinkContainer" style=""></div>
      <div class="youTubeLink">
        <a href="https://www.youtube.com/watch?v=hTvEALH9KtE" target="_blank">
          <i class="material-icons">play_circle</i>
          <span>Watch Video</span>
        </a>
      </div>
    </section>
    <section class="aboutUsContainer">
      <div class="aboutUs">
        <div class="topAboutUs">
          <div class="aboutUsImages">
            <div v-for="(image, index) in aboutUs.images" :key="index">
              <img :src="require(`@/assets/img/${image.src}`)" alt="About Us" />
            </div>
          </div>
          <div class="aboutUsDescription">
            <h3>Welcome To Bandhoo</h3>
            <h1>About Us</h1>
            <p>{{ aboutUs.topDescription }}</p>
            <p>{{ aboutUs.bottomDescription }}</p>
          </div>
        </div>
        <div class="bottomAboutUs">
          <div class="certificatesContainer">
            <div class="certificates">
              <div class="certificateDetail">
                <div class="verticleLine"></div>
                Bandhoo makes life simpler for Builders, Contractors, Material
                Suppliers, Architects, Foremen, and Workers
              </div>
              <div class="stamps">
                <div v-for="(image, index) in aboutUs.certificates" :key="index">
                  <img :src="require(`@/assets/img/${image.src}`)" alt="Certificate" />
                </div>
              </div>
              <div class="certificateDetail">
                <div class="verticleLine"></div>
                Download Bandhoo app for Android or IOS or Web from the
                appropriate link below.
              </div>
              <div class="downloadBandhooApp">
                <div v-for="(link, index) in appUtils" :key="index">
                  <a :href="link.downloadLink" @click="sendInformationToGoogleAnalitics(link.gtag)">
                    <img width="140" height="45" :src="require(`../assets/img/${link.image}`)" alt="Download App Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="btnContainer">
            <BandhooButton btn-text="More About Us" :dark-hover="false" location="about-us" :open-in-same-tab="true"></BandhooButton>
          </div>
        </div>
      </div>
    </section>
    <section class="bandhooClients">
      <div v-for="(client, index) in bandhooClients" :key="index" class="bandhooClient">
        <span class="statsBorderTop statsBorder"></span>
        <div class="bandhooClientImgContainer">
        <a href="">
          <img style="max-width: 150px;" :src="require(`../assets/img/clients/developers/${client.image}`)" :alt="client.name" loading="lazy" />
        </a>
      </div>
        <span class="statsBorderBottom statsBorder"></span>
      </div>
    </section>
  </div>
</template>

<script>
import BandhooButton from "@/components/Button.vue"
import BandhooStats from "@/components/BandhooStats.vue"
import { aboutUs, appUtils, bandhooClients, bandhooStats, rolesInfo, bandhooContacts, quickLinks } from '@/assets/db/static-data'
export default {
  name: 'BandhooHome',
  data() {
    return {
      appUtils,
      aboutUs,
      bandhooClients,
      quickLinks,
      bandhooContacts,
      bandhooStats,
      rolesInfo
    }
  },
  methods: {
    sendInformationToGoogleAnalitics(information) {
      this.$gtag.event(information);
    }
  },
  components: {
    BandhooButton,
    BandhooStats
  }
}
</script>

<style scoped>
/* hero section starts */
.hero {
  position: relative;
}

.heroImageContainer {
  background-image: url(../assets/img/slide6.jpg);
  opacity: .5;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroText {
  position: absolute;
  top: 200px;
  left: 8vw;
  padding: 12px;
}

.purposeHeading {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 70px;
  margin-top: 24px;
}

.heroDescription {
  margin-top: 24px;
}

.purposeDescription {
  display: inline-block;
  width: 70%;
  line-height: 30px;
  font-size: 1rem;
  font-weight: 600;
}

/* hero section ends */


/* roles Info starts */
.rolesInfoContainer {
  background-color: #1a191d;
  padding: 75px 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;
}

.roleInfo {
  background-color: #262529;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: 300ms linear;
}

.roleInfo:hover {
  background-color: white;
  color: black;
}

.roleInfo:hover .detailsArrow {
  background-color: #93ca12;
}

.roleInfo:hover .material-icons {
  color: white;
}

.roleInfo:hover .roleHeading>a {
  color: rgb(0, 0, 0);
}

.roleInfo>p {
  font-size: .85rem;
}

.roleInfo>h3>a {
  color: white;
}

.roleInfo>h3>a:hover {
  color: #7c9f29 !important;
}

.detailsArrow {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  transition: 300ms linear;
}

.rolesRedirect {
  display: flex;
  align-items: center;
}

.rolesRedirect .material-icons {
  color: black;
}

.rolesRedirect::before,
.rolesRedirect::after {
  background: black;
}

.detailsArrow:hover {
  background-color: black !important;
}

.detailsArrow:hover .material-icons {
  color: white;
}

/* roles info ends */


/* youtube link starts */
.bandhooYouTubeLink {
  height: 580px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.youTubeLinkContainer {
  background-image: url(../assets/img/bandhoo.jpg);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-attachment: fixed;
  opacity: .5;
  background-size: contain;
  z-index: -1;
}

.youTubeLink {
  margin-left: 36px;
}

.youTubeLink>a>.material-icons {
  color: black;
  font-size: 4rem;
  background-color: white;
  border-radius: 50%;
}

.youTubeLink>a {
  display: flex;
  align-items: center;
  gap: 16px;
}

.youTubeLink>a>span {
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  text-decoration: underline;
}

/* youTube link ends */


/* Bandhoo Clients */
.bandhooClients {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 36px;
}

.bandhooClientImgContainer {
  min-height: 75px; 
  min-width: 220px;
  display: flex; 
  align-items: center;
  justify-content: center;
}
.bandhooClient:hover .statsBorder{
  display: block;
}

/* about us starts */
.aboutUs {
  background-color: #1a191d;
  display: flex;
  flex-direction: column;
}

.topAboutUs {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 100px 24px 0 24px;
}

.aboutUsImages {
  width: 50%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.aboutUsImages>div {
  width: 50%;
}

.aboutUsImages>div>img {
  width: 100%;
}

.aboutUsDescription {
  width: 50%;
  font-weight: 500;
  font-size: .9rem;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.aboutUsDescription>h3 {
  color: #979598;
}

.aboutUsDescription>h1 {
  font-size: 2.4rem;
}

.certificatesContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.certificates {
  width: 520px;
  background-color: white;
  color: black;
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.stamps {
  display: flex;
  gap: 16px;
}

.stamps>div {
  border: 2px solid rgb(188, 188, 188);
}

.downloadBandhooApp {
  display: flex;
  gap: 16px;
  align-items: center;
}

.btnContainer {
  width: 50%;
}

.bottomAboutUs {
  padding: 0 24px 100px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: -120px;
}

.certificateDetail {
  display: flex;
  gap: 24px;
  font-size: .9rem;
  font-weight: 600;
  font-style: italic;
}

.verticleLine {
  border: 1px solid #b3d33c;
}
.bandhooClient  {
  position: relative;
}
.statsBorderTop{
    position: absolute;
    content: "";
    display: none;
    width: 17px;
    height: 17px;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 3px solid #b3d33c;
    border-left: 3px solid #b3d33c;
}
.statsBorderBottom{
    content: "";
    display: none;
    width: 17px;
    height: 17px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    border-right: 3px solid #b3d33c;
    border-bottom: 3px solid #b3d33c;
}

/* media queries starts */

/* mobile */
@media screen and (max-width: 550px) {
  .hero {
    height: 80vh;
  }

  .heroText {
    left: 0;
    margin-top: 36px;
    text-align: center;
  }

  .bandhooStatsContainer {
    grid-template-columns: 1fr;
  }

  .purposeHeading {
    font-size: 1.5rem;
    line-height: 30px;
    margin-top: 8px;
  }

  .purposeDescription {
    width: 100%;
    line-height: 20px;
    font-weight: 600;
    font-size: .85rem;
    text-align: center;
  }

  .rolesInfoContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .bandhooYouTubeLink {
    height: 500px;
  }

  .topAboutUs {
    flex-direction: column;
  }

  .aboutUsImages {
    flex-direction: column;
    width: 100%;
  }

  .aboutUsImages>div,
  .certificatesContainer,
  .aboutUsDescription,
  .moreAboutUsBtn,
  .aboutUsImages>div>img {
    width: 100%;
  }

  .certificatesContainer {
    margin-top: 50px;
    padding-top: 80px;
  }

  .btnContainer {
    width: 100%;
  }

  .bottomAboutUs {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
  }

  .bandhooClients {
    flex-direction: column;
  }

  .bandhooClients>div:not(:first-child) {
    display: none;
  }

}

@media screen and (max-width: 730px) and (min-width:550px) {
  .bandhooStatsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .purposeDescription {
    width: 100%;
    line-height: 24px;
  }

  .purposeHeading {

    font-size: 2.15rem;
    line-height: 45px;
  }

  .rolesInfoContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .topAboutUs {
    flex-direction: column;
  }

  .aboutUsImages {
    flex-direction: column;
    width: 100%;
  }

  .aboutUsImages>div,
  .certificates,
  .certificatesContainer,
  .aboutUsDescription,
  .aboutUsImages>div>img {
    width: 100%;
  }

  .certificatesContainer {
    margin-top: 50px;
    padding-top: 80px;
  }

  .bottomAboutUs {
    flex-direction: column;
    align-items: flex-start;
  }

  .bandhooClients {
    flex-direction: column;
  }

  .bandhooClients>div:not(:first-child) {
    display: none;
  }
}

/* tab */

@media screen and (min-width: 730px) and (max-width:980px) {

  .bandhooStatsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .purposeHeading {
    font-size: 2.25rem;
    line-height: 50px;
  }

  .purposeDescription {
    line-height: 24px;
  }

  .rolesInfoContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .topAboutUs {
    flex-direction: column;
  }

  .aboutUsImages,
  .purposeDescription {
    width: 100%;
  }

  .aboutUsImages>div,
  .certificates,
  .certificatesContainer,
  .aboutUsDescription,
  .aboutUsImages>div>img {
    width: 100%;
  }

  .certificatesContainer {
    margin-top: 50px;
    padding-top: 80px;
  }

  .bottomAboutUs {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
  }
}
</style>