import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import { GOOGLE_TRACKING_ID } from './assets/db/config';
import { createI18n } from 'vue-i18n'
import hindi from "@/messages/hindi.json"
import english from "@/messages/english.json"
import { filters } from '@/assets/filters';
const i18n = createI18n({
  locale: 'en',
  messages: {
    en:english,
    hi: hindi
  }
})

const app = createApp(App)
.use(i18n)
.use(router)
.use(VueGtag, {
    config: { id: GOOGLE_TRACKING_ID }
  })
  
filters.msg = msg;
app.config.globalProperties.$filters = filters;
app.mount('#app')


function msg(message){
  return i18n.global.t(message);
}