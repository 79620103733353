<template>
  <div class="roleContainer">
    <PageTopImage 
      :image-path="imagePath" 
      top-image-heading="For Thekedars"
      top-image-description="Customer Testimonial">
    </PageTopImage>
    <section class="main">
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          <div class="btnSection">
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url"></BandhooButton>
            </div>
          </div>
          <div class="thekedarText">
          <h1>For Thekedar</h1>
          <p>बंधू ऐप पर ठेकेदार आइटम रेट के टेंडरों का काम ले सकते हैं | यही नहीं, ठेकेदार अपने काम के लिए लेबर भी बंधू ऐप से प्राप्त कर सकते हैं | हजारों ठेकेदार बंधू एप का इस्तेमाल करके लाभ उठा रहे हैं |</p>
          </div>
          <div class="watchVideo">ये विडिओ देखें और समझें - <a href="https://youtu.be/zKPJkrDjAY0"
              target="_blank">https://youtu.be/zKPJkrDjAY0</a></div>

          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
              src="https://www.youtube.com/embed/wTEp6A6euKk?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.bandhoo.com&widgetid=1"
              id="widget4"></iframe>
          </div>
          <div class="thekedarPoint">
            <span></span>
            <p></p>
            <span></span>
          </div>
          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
              src="https://www.youtube.com/embed/D1jFoWFzLxM?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.bandhoo.com&widgetid=3"
              id="widget4"></iframe>
          </div>
          <div class="thekedarPoint">
            <span></span>
            <p>Kya faayde ho sakte hain Bandhoo istemaal karke</p>
            <span></span>
          </div>
          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
              src="https://www.youtube.com/embed/jfxCyoLHz8E?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.bandhoo.com&widgetid=5"
              id="widget4"></iframe>
          </div>
          <div class="thekedarPoint">
            <span></span>
            <p>Score kaise badhayen</p>
            <span></span>
          </div>
          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
              src="https://www.youtube.com/embed/8IY3vX60ksc?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.bandhoo.com&widgetid=7"
              id="widget4"></iframe>
          </div>
          <div class="thekedarPoint">
            <span></span>
            <p>Maasik Shulk Kyon Bharen</p>
            <span></span>
          </div>
          <div class="iframeContainer">
            <iframe frameborder="0" allowfullscreen="1"
              defer="true"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
              src="https://www.youtube.com/embed/uWPYHnCrTpk?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.bandhoo.com&widgetid=9"
              id="widget4"></iframe>
          </div>
          <div class="btnSection">
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url"></BandhooButton>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import RoleChange from "@/components/RoleChange.vue"
import PageTopImage from "@/components/PageTopImage.vue"
import { homeOwnerInfo } from "@/assets/db/home-owner-data"
import { appUtils, register } from "@/assets/db/static-data"
import BandhooButton from "@/components/Button.vue"
export default {
  name: 'BandhooThekedar',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
      homeOwnerInfo,
      appUtils,
      register
    }
  },
  components: {
    RoleChange,
    BandhooButton,
    PageTopImage
  }

}
</script>

<style scoped>
.btnSection {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
}

.thekedarText {
  margin-top: 24px;
  color: #555555;
}
h1 {
  color: black;
}
.downloadSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.downloadAndroidApp {
  display: flex;
  gap: 16px;
}

.homeOwnerText {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: .9rem;
  font-weight: 600;
}

ol {
  margin-left: 16px;
}

iframe,
.aboutRole,
.iframeContainer {
  width: 100%;
}

.thekedarPoint {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  font-weight: 600;
  color: #3e3e3e;

}

.watchVideo {
  margin: 16px 0;
}

.watchVideo>a {
  color: black;
  font-weight: 600;

}

.watchVideo>a:hover {
  color: rgb(101, 198, 4);
}

.thekedarPoint>span {
  border: 1px solid rgb(208, 208, 208);
}
</style>
