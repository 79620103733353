<template>
  <div>
    <BandhooNavbar></BandhooNavbar>
    <template v-if="component == 'developer'">
      <BandhooDeveloper></BandhooDeveloper>
    </template>
    <template v-else-if="component == 'contractor'">
      <BandhooContractor></BandhooContractor>
    </template>
    <template v-else-if="component == 'thekedar'">
      <BandhooThekedar></BandhooThekedar>
    </template>
    <template v-else-if="component == 'worker'">
      <BandhooWorker></BandhooWorker>
    </template>
    <template v-else-if="component == 'material-supplier'">
      <MaterialSupplier></MaterialSupplier>
    </template>
    <template v-else-if="component == 'home-owner'">
      <HomeOwner></HomeOwner>
    </template>
    <template v-else-if="component == 'ai_project_monitoring'">
      <Constrapage></Constrapage>
    </template>
    
    <!-- show the default solution page that has role change section -->
    <template v-else>
      <BandhooSolutions></BandhooSolutions>
    </template>
    <BandhooFooter></BandhooFooter>
  </div>
</template>

<script>
import BandhooNavbar from "@/components/Nav.vue";
import BandhooFooter from "@/components/Footer.vue";
import RoleChange from "@/components/RoleChange.vue";
import PageTopImage from "@/components/PageTopImage.vue";
import BandhooContractor from "@/components/Contractor.vue";
import BandhooDeveloper from "@/components/Developer.vue";
import BandhooWorker from "@/components/Worker.vue";
import HomeOwner from "@/components/HomeOwner.vue";
import MaterialSupplier from "@/components/MaterialSupplier.vue";
import BandhooThekedar from "@/components/Thekedar.vue";
import BandhooSolutions from "@/components/Solutions.vue";
import Constrapage from "@/components/constra/constrapage.vue";

export default {
  name: "SolutionPage",
  data() {
    return {
      imagePath: "ptt_service-1.jpg",
    };
  },
  computed: {
    component() {
      return this.$route.name;
    },
  },
  components: {
    BandhooNavbar,
    BandhooFooter,
    PageTopImage,
    RoleChange,
    BandhooContractor,
    BandhooDeveloper,
    HomeOwner,
    MaterialSupplier,
    BandhooWorker,
    BandhooThekedar,
    BandhooSolutions,
    Constrapage
  },
};
</script>

<style scoped>
.solutionsContiner {
  background-color: #fff;
  padding: 36px 24px;
}
</style>
