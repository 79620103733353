<template>
    <div class="citiesContainer">
      <div class="cityListTitle">
         <h2>  {{ title }}</h2>
      </div>
      <div class="cityList">
        <div v-for="(city, index) in popularCities" :key="index"  @click="sendInformationToGoogleAnalitics('website_popularcity_list_clicked')">
            <router-link :to="path + '?capabilityId=' + skill + '&pincode=' + city.pincode">
              {{ city.name }}
           </router-link>
            <span v-if="index  != popularCities.length - 1"> | </span> 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'popularCityList',
    props:['popularCities', 'title', 'skill'],
    data(){
      return {
        path: location.pathname
     }
    },
    methods:{
      sendInformationToGoogleAnalitics(information) {
        this.$gtag.event(information, {});
      }
    }
  }
  </script>
  
  <style scoped>
  .citiesContainer{
      padding: 10px;
  }
  .cityListTitle{
      display: flex;
      text-transform: capitalize;
      text-align: center;
      justify-content: center;
      margin: 10px;
  }
  .cityList{
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
  }
  .cityList a{
      color: white;
      font-weight: bold;
  }
  </style>