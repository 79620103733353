<template>
  <div class="roleChangeContainer">
    <div class="roleChange">
      <div class="ourSolutionHeading">
        <span>Our Solution</span>
      </div>
      <div v-for="(role, index) in rolesInfo" class="roleLink" :key="index">
        <RouterLink @click="sendInformationToGoogleAnalitics(role.gtag)" :to="role.url">
          <span class="role">{{ role.role }}</span>
        </RouterLink>
      </div>
    </div>
    <div class="contacts">
      <h1>LET’S START WORK TOGETHER</h1>
      <div class="contactHeading">Please feel free to contact us. We will get back to you within 1-2 business days.
        Or
        just call us now.
      </div>
      <div class="contactLinks">
        <span v-for="(contact, index) in bandhooContacts" :key="contact.contactInfo + index" class="contactInfo">
          <a href="">
            <template v-if="contact.name != 'WhatsApp'">
              <i class="material-icons">{{ contact.icon }}</i>
            </template>
            <template v-else>
              <img style="color: white; height:22px" :src="require(`@/assets/img/${contact.icon}`)"
                alt="Whatsapp Image" />
            </template>
            <span>{{ contact.contactInfo }}</span>
          </a>
        </span>
      </div>
      <div class="requestDemoBtn">
        <BandhooButton :btn-text="requestDemo.name" :dark-hover="true" :location="requestDemo.url"></BandhooButton>
      </div>
    </div>
  </div>
</template>

<script>
import BandhooButton from "@/components/Button.vue"
import { rolesInfo, bandhooContacts, requestDemo } from '@/assets/db/static-data'
export default {
  name: 'RoleChange',
  data() {
    return {
      bandhooContacts,
      rolesInfo,
      requestDemo
    }
  },
  methods: {
    sendInformationToGoogleAnalitics(information) {
      console.log("Sending information to google analytics...  " + information);
      this.$gtag.event(information);
      console.log("Information sent to google analytics...");
    }
  },
  components: {
    BandhooButton
  }

}
</script>

<style>
.requestDemoBtn,
.requestDemoBtn button {
  width: 100%;
}

.solutionsContainer {
  background-color: white;
  color: black;
}

.bottomRequestDemo {
  margin-bottom: 50px;
}

.bottomRequestDemo>div,
.aboutRoleRequestBtn div {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 550px) {
  .roleChangeContainer {
    width: 100%;
  }

}

@media screen and (max-width: 730px) and (min-width:550px) {
  .roleChangeContainer {
    width: 100%;
  }
}
</style>

<style scoped>
.content {
  margin-top: 50px;
}

.roleChange {
  display: flex;
  flex-direction: column;
  border-top: .75px solid rgb(215, 215, 215);
  border-bottom: .5px solid rgb(215, 215, 215);
}

.ourSolutionHeading {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.roleLink:hover {
  background-color: #b3d33c;
}

.roleChange>div:hover .role {
  color: white;
}

.contacts {
  background-color: black;
  color: white;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.contactInfo,
.contactHeading,
.contactLinks {
  padding: 0 36px;
  font-size: 0.9rem;
}
.contacts>h1 {
  padding: 0 36px;
}

.contactInfo>a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.contacts>h1 {
  margin-top: 24px;
}

.roleChangeContainer {
  max-width: 680px;
  width: 45%;
}

.roleLink {
  height: 60px;
  border-top: .75px solid rgb(224, 224, 224);
  border-left: .75px solid rgb(224, 224, 224);
  border-right: .75px solid rgb(224, 224, 224);
  transition: all 300ms linear;
}

.ourSolutionHeading {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #262529;
  font-size: 1.5rem;
  font-weight: bold;
}

.ourSolutionHeading>span {
  margin-left: 16px;
  color: white;
}


.roleLink>a {
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: rgb(101, 101, 101);
  width: 100%;
  height: 100%;
}

.contactLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
}
.router-link-active {
  background-color: #b3d33c !important;
  color: white !important;
}

@media screen and (max-width: 550px) {
  .roleChangeContainer {
    width: 100%;
  }

}

@media screen and (max-width: 730px) and (min-width:550px) {
  .roleChangeContainer {
    width: 100%;
  }
}

</style>
