<template>
    <div>
      <BandhooNavbar></BandhooNavbar>
      <Home></Home>
      <BandhooFooter></BandhooFooter>
    </div>
  </template>
  
  <script>
  import BandhooNavbar from "@/components/Nav.vue"
import BandhooFooter from "@/components/Footer.vue"
import Home from "@/components/Home.vue"
import { aboutUs, appUtils, bandhooClients, bandhooContacts, bandhooStats } from '@/assets/db/static-data'

export default {
  name: 'App',
  data() {
    return {
      aboutUs,
      appUtils,
      bandhooClients,
      bandhooStats,
      bandhooContacts
    }
  },
  methods: {
    sendInformationToGoogleAnalitics(information) {
      console.log("Sending information to google analytics...  " + information);
      this.$gtag.event(information);
      console.log("Information sent to google analytics...");
    }
  },
  created(){
    this.sendInformationToGoogleAnalitics("website_visits")
  },
  components: {
    BandhooNavbar,
    Home,
    BandhooFooter
  }
}
</script>
  
  <style scoped>
  
  </style>

  