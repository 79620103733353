<template>
  <div class="roleContainer">
    <PageTopImage 
      :image-path="imagePath" 
      top-image-heading="For Material Suppliers"
      top-image-description="Get access to material supply RFQs (Tenders) from clients"></PageTopImage>
    <section class="main">
      <div class="rolesImage"></div>
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          <div class="btnSection">
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url"></BandhooButton>
            </div>
          </div>
          <div class="contractorText">
            <h1>{{ supplierInfo.supplierMainHeading }}</h1>
            <p>{{supplierInfo.supplierSubHeading }}</p>
            <div class="benifitList">
              <ol>
                <li v-for="(item, index) in supplierInfo.supplierList" :key="index">{{ item.listItem }}</li>
              </ol>
            </div>
          </div>
          <div>
            <div class="iframeContainer">
              <iframe frameborder="0" allowfullscreen="1"
                defer="true"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                title="Managing Construction Projects Digitally" width="640" height="360"
                src="https://www.youtube.com/embed/i6gl5DMvzMA?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.bandhoo.com&amp;widgetid=1"
                id="widget2">
              </iframe>
            </div>
            <div class="btnSection">
              <div class="aboutRoleRequestBtn">
                <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url">
                </BandhooButton>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import BandhooButton from "@/components/Button.vue"
import RoleChange from "@/components/RoleChange.vue"
import PageTopImage from "@/components/PageTopImage.vue"
import { register, requestDemo } from '@/assets/db/static-data'
import { supplierInfo } from "@/assets/db/material-supplier-data"
export default {
  name: 'MaterialSupplier',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
      requestDemo,
      register,
      supplierInfo
    }
  },
  components: {
    BandhooButton,
    RoleChange,
    PageTopImage
  }

}
</script>

<style scoped>
.btnSection {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
}
.contractorText{
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #555555;
}
h1 {
  color: black;
}
ol {
  margin-left: 16px;
}
iframe,
.aboutRole,
.iframeContainer {
  width: 100%;
}
</style>
