<template>
  <div class="skillContainer">
    <div class="skillListTitle">
       <h2>  {{ title }}</h2>
    </div>
    <div class="skillList">
      <div v-for="(skill, value, index) in skills" :key="index" @click="sendInformationToGoogleAnalitics('website_skill_list_clicked')">
        <template v-if="value != 'Other'">
          <router-link :to="path + '?capabilityId=' + skill.value + '&pincode=' + (pincode ? pincode : '')">
            {{ skill.name }}
         </router-link>
          <span v-if="index  != Object.keys(skills).length - 2"> | </span> 
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skillList',
  props:['skills', 'title', 'pincode'],
  data(){
    return {
      path: location.pathname
    }
  },
  methods:{
    sendInformationToGoogleAnalitics(information) {
      this.$gtag.event(information, {});
    }
  }
}
</script>

<style scoped>
.skillContainer{
    padding: 10px;
}
.skillListTitle{
    display: flex;
    text-transform: capitalize;
    text-align: center;
    justify-content: center;
    margin: 10px;
}
.skillList{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.skillList a{
    color: white;
    font-weight: bold;
}
</style>