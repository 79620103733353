<template>
  <div class="roleContainer">
    <PageTopImage 
      :image-path="imagePath" 
      top-image-heading="For Workers"
      top-image-description="Customer Testimonial">
    </PageTopImage>
    <section class="main">
      <div class="rolesImage"></div>
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          <div class="btnSection">
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url"></BandhooButton>
            </div>
          </div>
          <div class="workerText">
          <h1>For Workers</h1>
          <p>श्रमिक बंधू ऐप पर अपने मन मुताबिक दिहाड़ी का काम प्राप्त कर सकते हैं | जिन श्रमिकों के पास स्मार्ट फोन नहीं है वो बंधू ऐप के डिजिटल श्रमिक प्रोग्राम के तहत स्मार्ट फोन भी प्राप्त कर सकते हैं</p>
          </div>
          <div class="watchVideo">ये विडिओ देखें और समझें - <a href="https://youtu.be/zKPJkrDjAY0" target="_blank">https://youtu.be/zKPJkrDjAY0</a></div>
          <div class="workerPoint">
            <span></span>
            <p>Kaise phone ke zariye kaam paa sakte hain</p>
            <span></span>
          </div>

          <div class="iframeContainer">
          <iframe frameborder="0" allowfullscreen="1"
            defer="true"
            loading="lazy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            title="How Real Time Bidding for Tenders Works on Bandhoo" width="640" height="360"
            src="https://www.youtube.com/embed/zKPJkrDjAY0?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.bandhoo.com&widgetid=1"
            id="widget4"></iframe>
        </div>
        <div class="btnSection">
            <div class="aboutRoleRequestBtn">
              <BandhooButton :btn-text="register.name" :dark-hover="true" :location="register.url"></BandhooButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import RoleChange from "@/components/RoleChange.vue"
import BandhooButton from "@/components/Button.vue"
import PageTopImage from "@/components/PageTopImage.vue"
import { homeOwnerInfo } from "@/assets/db/home-owner-data"
import { appUtils, register } from "@/assets/db/static-data"
export default {
  name: 'BandhooWorker',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
      homeOwnerInfo,
      appUtils,
      register
    }
  },
  components: {
    RoleChange,
    BandhooButton,
    PageTopImage
  }

}
</script>

<style>
.roleContainer {
  background-color: white;
  color: black;
}

/* media queries starts */

/* mobile */
@media screen and (max-width: 550px) {
  .roleContainer {
    font-size: .85rem;
  }

  .content {
    flex-direction: column;
  }

  .roleContainer {
    padding: 0;
  }

  .ytContainer {
    align-items: flex-start;
  }

  iframe,
  .aboutRole,
  .iframeContainer {
    width: 100%;
  }

  .benifitList {
    flex-direction: column;
  }

  .btnSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .btnSection>*,
  .animationBtn {
    width: 100%;
  }
}

@media screen and (max-width: 730px) and (min-width:550px) {
  .content {
    flex-direction: column;
  }

  .ytContainer {
    align-items: flex-start;
  }

  .roleContainer {
    padding: 0;
  }

  iframe,
  .aboutRole,
  .iframeContainer {
    width: 100%;
  }

  .btnSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .btnSection>*,
  .animationBtn {
    width: 100%;
  }
}

/* tab */

@media screen and (min-width: 730px) and (max-width:980px) {
  .btnSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .btnSection>*,
  .animationBtn {
    width: 100%;
  }

}
</style>
<style scoped>
.workerText {
  margin-top: 24px;
  color: #555555;
}
h1 {
  color: black;
}
.btnSection {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
}

.downloadSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.downloadAndroidApp {
  display: flex;
  gap: 16px;
}

.homeOwnerText {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: .9rem;
  font-weight: 600;
}

ol {
  margin-left: 16px;
}

iframe,
.aboutRole,
.iframeContainer {
  width: 100%;
}
.workerPoint {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  font-weight: 600;
  color: #3e3e3e;

}
.watchVideo {
  margin: 16px 0;
}
.watchVideo > a {
  color: black;
  font-weight: 600;

}
.watchVideo > a:hover {
  color: rgb(101, 198, 4);
}
.workerPoint>span {
  border: 1px solid rgb(208, 208, 208);
}
</style>
