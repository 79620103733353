import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/Pages/HomePage.vue'
import SolutionsPage from '@/Pages/SolutionsPage.vue'
import FindContractorsComponent from '@/components/FindContractorsComponent.vue'
import Constrapage from '@/components/constra/constrapage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/findcontractors',
    name: 'findContractors',
    component: () => import('@/Pages/FindContractorsPage.vue')
  },
  {
    path: '/findcontractorsframe',
    name: 'findcontractorsFrame',
    component: () => import('@/components/FindContractorsComponent.vue')
  },
  {
    path: '/findtenders',
    name: 'findTenders',
    component: () => import('@/Pages/FindTendersPage.vue')
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import('@/Pages/SolutionsPage.vue'),
    children: [
      {
        path: 'contractor',
        name: 'contractor',
        component: SolutionsPage,
      },
      {
        path: 'developer',
        name: 'developer',
        component: SolutionsPage,
      },
      {
        path: 'worker',
        name: 'worker',
        component: SolutionsPage,
      },
      {
        path: 'thekedar',
        name: 'thekedar',
        component: SolutionsPage,
      },
      {
        path: 'thekedar',
        name: 'thekedar',
        component: SolutionsPage,
      },
      {
        path: 'home-owner',
        name: 'home-owner',
        component: SolutionsPage,
      },
      {
        path: 'material-supplier',
        name: 'material-supplier',
        component: SolutionsPage
      },
      {
        path: 'ai_project_monitoring',
        name: 'ai_project_monitoring',
        component: Constrapage,
      }
    ]
  },
  {
    path: '/clients',
    name: 'Clients',
    component:  () => import('@/Pages/ClientsPage.vue')
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('@/Pages/AboutUsPage.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('@/Pages/ContactUsPage.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import('@/Pages/FAQPage.vue')
  },
  {
    path: '/request-demo',
    name: 'Reques Demo',
    component: () => import('@/Pages/RequestDemoPage.vue')
  },
  {
    path: '/digital-shramik',
    name: 'Digital Shramik',
    component: () => import('@/Pages/DigitalShramikPage.vue')
  },
  {
    path: '/terms-of-use',
    name: 'Terms of Use',
    component: () => import('@/Pages/TermsAndConditionsPage.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('@/Pages/PrivacyPolicyPage.vue')
  },
  {
    path: '/:pathMatch([+]91?[0-9]{10}|[0-9]{10})',
    name: 'contractorWebsite',
    component: () => import('@/Pages/ContractorWebsite.vue'),
  },
  {
    path:'/:pathMatch(.*)',
    name: 'HomePage',
    component: () => import('@/Pages/HomePage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
})

export default router
