<template>
  <div class="roleContainer">
    <PageTopImage 
      :image-path="imagePath"
      top-image-heading="For Home Owners"
      top-image-description="How Bandhoo’s PLM helps you digitally run your Procurement and Projects"></PageTopImage>
    <section class="main">
      <div class="rolesImage"></div>
      <div class="content">
        <RoleChange></RoleChange>
        <div class="aboutRole">
          <div class="homeOwnerText">
            <h1>{{ homeOwnerInfo.homeOwnerMainHeading }}</h1>
            <p>{{ homeOwnerInfo.homeOwnerSubHeading }}</p>
            <div class="benifitList">
              <ol>
                <li v-for="(item, index) in homeOwnerInfo.homeOwnerList" :key="index">{{ item.listItem }}</li>
              </ol>
            </div>
            <p>{{ homeOwnerInfo.homeOwnerDescription }} <a href="mailto:info@bandhoo.com">info@bandhoo.com</a></p>
          </div>
          <div class="downloadSection">
            <h1>Download App</h1>
            <div class="downloadAndroidApp">
              <div v-for="(link, index) in appUtils" :key="index">
                <a :href="link.downloadLink" @click="sendInformationToGoogleAnalitics(link.gtag)">
                  <img width="140" height="45" :src="require(`../assets/img/${link.image}`)" alt="Download App Logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import RoleChange from "@/components/RoleChange.vue"
import { homeOwnerInfo } from "@/assets/db/home-owner-data"
import PageTopImage from "@/components/PageTopImage.vue"
import { appUtils } from "@/assets/db/static-data"
export default {
  name: 'MaterialSupplier',
  data() {
    return {
      imagePath: 'ptt_service-1.jpg',
      homeOwnerInfo,
      appUtils
    }
  },
  methods: {
    sendInformationToGoogleAnalitics(information) {
      this.$gtag.event(information);
    }
  },
  components: {
    RoleChange,
    PageTopImage,
  }

}
</script>


<style scoped>
.btnSection {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
}
.downloadSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.downloadAndroidApp {
  display: flex;
  gap: 16px;
}
.homeOwnerText {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: .9rem;
  font-weight: 600;
  color: #555555;
}
h1 {
  color: black;
}
ol {
  margin-left: 16px;
}

iframe,
.aboutRole,
.iframeContainer {
  width: 100%;
}
</style>
